import { HeaderOptions, HeaderOptionsTypes } from '@shared/components';

export function relatorioMedicaoFiscalLinhaTableHeader(fluido: any) {
  let headers: HeaderOptions[] = [
    {
      description: 'id',
      attribute: 'id'
    },
    {
      description: 'Estação',
      attribute: 'estacao'
    },
    {
      description: 'Status',
      attribute: 'status'
    },
    {
      description: 'Ponto de Medição',
      attribute: 'ponto_medicao'
    },
    {
      description: 'Tipo de Medição',
      attribute: 'tipo_medicao'
    },
    {
      description: 'Fluido',
      attribute: 'fluido'
    },
    {
      description: 'Data/Hora de Início',
      attribute: 'data_hora_inicio'
    },
    {
      description: 'Data/Hora de Fim',
      attribute: 'data_hora_fim'
    },
    {
      description: 'Volume Bruto Corrigido (m³)',
      attribute: 'volume_bruto_corrigido',
      type: HeaderOptionsTypes.NUMBER
    },
    {
      description: 'Volume Líquido Corrigido (m³)',
      attribute: 'volume_liquido_corrigido',
      type: HeaderOptionsTypes.NUMBER
    },
    {
      description: 'Volume Operacional (m³)',
      attribute: 'volume_operacional',
      type: HeaderOptionsTypes.NUMBER
    },
    {
      description: 'Data de Criação',
      attribute: 'data_criacao'
    }
  ];
  if (fluido == 'Gás') {
    headers.forEach((header) => {
      if (header.description === 'Volume Líquido Corrigido (m³)') {
        header.description = 'Volume Corrigido (m³)';
      }
    });

    headers = headers.filter((header) => header.description !== 'Volume Bruto Corrigido (m³)');
  }

  return headers;
}

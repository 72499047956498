import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ExportTableComponent } from '@shared/components/export-table';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';
import {
  ButtonComponent,
  CustomPaginatorComponent,
  DeleteDialog,
  DialogCancelComponent,
  DialogDefaultComponent,
  HeaderComponent,
  InfoBlock,
  InputFilterComponent,
  LogoComponent,
  MultipleInfoBlock,
  PaginatorIntl,
  PieChartComponent,
  SelectorCheckboxFilterComponent,
  SubtitleComponent,
  TableComponent,
  TitleComponent,
  UnidadePressaoCalcComponent,
  UnidadeResultadoCalcComponent
} from './components';
import { InfoBlockDetailComponent } from './components/info-block-datasource/info-block-detail.component';
import { ClickOutsideDirective } from './directives';
import { InputComponent, InputFileComponent, MultipleSelecterComponent, Selecter2Component, SelecterComponent } from './forms';
import { MaterialAngularShared } from './material-angular-shared.module';
import { FilterEquipamentoPipe, PhonePipe } from './pipes';
import { PrimeNGSharedModule } from './primeng-shared.module';

@NgModule({
  imports: [
    InputComponent,
    InputFilterComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialAngularShared,
    NgxFileDragDropModule,
    HighchartsChartModule,
    PrimeNGSharedModule
  ],
  declarations: [
    ButtonComponent,
    DeleteDialog,
    DialogCancelComponent,
    DialogDefaultComponent,
    HeaderComponent,
    LogoComponent,
    TableComponent,
    SelectorCheckboxFilterComponent,
    TitleComponent,
    SubtitleComponent,
    ClickOutsideDirective,
    SelecterComponent,
    Selecter2Component,
    MultipleSelecterComponent,
    InfoBlock,
    MultipleInfoBlock,
    InfoBlockDetailComponent,
    ExportTableComponent,
    UnidadePressaoCalcComponent,
    UnidadeResultadoCalcComponent,
    InputFileComponent,
    PhonePipe,
    FilterEquipamentoPipe,
    CustomPaginatorComponent,
    PieChartComponent
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialAngularShared,
    InputComponent,
    ButtonComponent,
    DeleteDialog,
    HeaderComponent,
    LogoComponent,
    TableComponent,
    SelectorCheckboxFilterComponent,
    TitleComponent,
    SubtitleComponent,
    CommonModule,
    ClickOutsideDirective,
    SelecterComponent,
    Selecter2Component,
    MultipleSelecterComponent,
    InfoBlock,
    MultipleInfoBlock,
    InfoBlockDetailComponent,
    ExportTableComponent,
    UnidadePressaoCalcComponent,
    UnidadeResultadoCalcComponent,
    InputFilterComponent,
    InputFileComponent,
    FilterEquipamentoPipe,
    HighchartsChartModule,
    CustomPaginatorComponent,
    PieChartComponent,
    PrimeNGSharedModule
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: PaginatorIntl
    }
  ]
})
export class SharedModule {}

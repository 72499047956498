import { Route } from '@angular/router';
import { ERoutes } from '@core/enums';
import { AuthGuard, PublicGuard } from '@core/guards';
import { AlterarSenhaComponent } from '@features/auth/pages/alterar-senha/alterar-senha.component';

export const AppRoutes: Route[] = [
  {
    path: '',
    redirectTo: ERoutes.HOME,
    pathMatch: 'full'
  },
  {
    path: ERoutes.PATH_AUTH,
    loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [PublicGuard]
  },
  {
    path: ERoutes.ALTERAR_SENHA,
    component: AlterarSenhaComponent,
    title: 'Alterar Senha',
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/estacoes/estacoes.module').then((m) => m.EstacoesModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/empresas/empresas.module').then((m) => m.EmpresasModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/cargos/cargos.module').then((m) => m.CargosModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/tags/tags.module').then((m) => m.TagsModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/campos/campos.module').then((m) => m.CamposModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/perfis/perfis.module').then((m) => m.PerfisModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('./features/grupo-motivo-ocorrencia/grupos-motivos-ocorrencias.module').then((m) => m.GruposMotivosOcorrenciasModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('./features/agrupamento-motivo-providencia-responsavel/agrupamentos-motivos-providencias-responsaveis.module').then(
        (m) => m.AgrupamentosMotivosProvidenciasResponsaveisModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/zonas-produtora/zonas-produtora.module').then((m) => m.ZonasProdutoraModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/ponto-medicao').then((m) => m.PontoMedicaoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/medicoes-fiscais-linha/medicoes-fiscais-linha.module').then((m) => m.MedicoesFiscaisLinhaModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/pocos/pocos.module').then((m) => m.PocosModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/malha-injecao/malhas-injecoes.module').then((m) => m.MalhasInjecoesModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('@features/gestao-prazo-instrumento/gestao-prazo-instrumento.module').then((m) => m.GestaoPrazoInstrumentoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/satelite/satelites.module').then((m) => m.SatelitesModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/ramal-eletrico/ramais-eletricos.module').then((m) => m.RamaisEletricosModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/motivo-ocorrencia/motivos-ocorrencias.module').then((m) => m.MotivosOcorrenciasModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/grupos-providencias/grupos-providencias.module').then((m) => m.GruposProvidenciasModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('@features/providencia-ocorrencia/providencias-ocorrencias.module').then((m) => m.ProvidenciasOcorrenciasModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/responsavel-ocorrencia/responsaveis-ocorrencias.module').then((m) => m.ResponsaveisOcorrencias),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/ocorrencia/ocorrencias.module').then((m) => m.OcorrenciasModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/testes-injecao/testes-injecao.module').then((m) => m.TestesInjecaoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/colaboradores/colaboradores.module').then((m) => m.ColaboradoresModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/usuarios/usuarios.module').then((m) => m.UsuariosModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/tipos-responsaveis/tipos-responsaveis.module').then((m) => m.TiposResponsaveis),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/testes-poco/testes-poco.module').then((m) => m.TestesPocoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/testes-poco-validacao/testes-poco-validacao.module').then((m) => m.TestesPocoValidacaoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('@features/relatorio-analitico-injecoes-diarias/relatorio-analitico-injecoes-diarias.module').then(
        (m) => m.RelatorioAnaliticoInjecoesDiariasModules
      ),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/reservatorios/reservatorios.module').then((m) => m.ReservatoriosModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/amostra/amostra.module').then((m) => m.AmostraModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/bsws/bsws.module').then((m) => m.BswsModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/captacao/captacao.module').then((m) => m.CaptacaoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/medicoes-operacionais-queima/medicao-operacional-queima.module').then((m) => m.QueimaModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/cromatrografia/cromatografia.module').then((m) => m.CromatografiaModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/densidade/densidade.module').then((m) => m.DensidadeModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/fe&rs/fers.module').then((m) => m.FERSModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/associacao-zonas/associacao-zonas.module').then((m) => m.AssociacaoZonasModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/rgos/rgos.module').then((m) => m.RGOSModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/producoes-diarias/producoes-diarias.module').then((m) => m.ProducoesDiariasModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/bmp/bmp.module').then((m) => m.BMPModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/equipamento-laboratorio/equipamento.module').then((m) => m.EquipamentoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/unidade-bombeio/unidades-bombeio.module').then((m) => m.UnidadesBombeioModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/h2s/h2s.module').then((m) => m.H2SModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/salinidade/salinidade.module').then((m) => m.SalinidadeModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/gas-ventilado/gas-ventilado.module').then((m) => m.GasVentiladoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/config-superficie-poco').then((m) => m.ConfigSuperficiePocoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/transformador/transformador.module').then((m) => m.TransformadorModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/cabecote/cabecote.module').then((m) => m.CabecoteModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('@features/relatorio-medicao-fiscal/relatorio-medicao-fiscal.module').then((m) => m.RelatorioMedicaoFiscalModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('@features/relatorio-medicao-operacional-queima/relatorio-medicao-operacional-queima.module').then(
        (m) => m.RelatorioMedicaoFiscalModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/fabricante/fabricante.module').then((m) => m.FabricanteModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('@features/relatorio-transferencia-fiscal-tanque/relatorio-transferencia-fiscal-tanque.module').then(
        (m) => m.RelatorioTransferenciaFiscalTanqueModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/cluster/cluster.module').then((m) => m.ClusterModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/instrumento/instrumento.module').then((m) => m.InstrumentoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/lacres/lacres.module').then((m) => m.LacresModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/fornecedores/fornecedores.module').then((m) => m.FornecedoresModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/relatorio-perda/relatorio-perda.module').then((m) => m.RelatorioPerdaServiceModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/no/no.module').then((m) => m.NoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('@features/gestao-prazo-teste-poco/gestao-prazo-teste-poco.module').then((m) => m.GestaoPrazoTestePocoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/potencial/potencial.module').then((m) => m.PotencialModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('@features/relatorio-producoes-diarias/relatorio-producoes-diarias.module').then((m) => m.RelatorioProducoesDiariasModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/escoamento/escoamento.module').then((m) => m.EscoamentoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/medicoes-fiscais-tanque/medicoes-fiscais-tanque.module').then((m) => m.MedicoesFiscaisTanque),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/relatorio-mtbf/relatorio-mtbf.module').then((m) => m.RelatorioMtbfModule),
    canActivate: [AuthGuard]
  }
];

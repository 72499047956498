<fieldset (clickOutside)="handleClickOutside()" class="relative my-4 w-full disabled:bg-transparent" [disabled]="isReadonly">
  <div
    class="input-wrapper relative flex items-center justify-between"
    [ngClass]="{ 'border-gray-300': isReadonly, 'border-gray-400': !isReadonly }"
  >
    <input
      #selectInput
      id="selecter"
      [name]="id"
      [placeholder]="label"
      class="input-base peer bg-gray-100"
      [ngClass]="{ 'hover:cursor-default': isReadonly, 'hover:cursor-pointer': !isReadonly }"
      [formControl]="selectControl"
      (click)="handleClick()"
      autocomplete="off"
      readonly
    />

    <label
      [attr.for]="id"
      class="pointer-events-none absolute -top-2.5 left-2 bg-gray-100 px-2 text-sm font-medium text-dark-blue-puple transition-all duration-150 peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-base peer-placeholder-shown:font-normal peer-placeholder-shown:text-dark-blue-puple"
      [ngClass]="{ 'text-gray-300 peer-placeholder-shown:text-gray-300': isReadonly }"
    >
      {{ label }}</label
    >

    <div class="flex items-center gap-2">
      <button
        *ngIf="hasValue()"
        type="button"
        title="Limpar seleção"
        (click)="handleClearSelection()"
        [disabled]="isReadonly"
        class="flex items-center rounded bg-gray-100 p-0.5 transition-all duration-150 disabled:bg-gray-100 hover:bg-gray-200 disabled:hover:bg-gray-100"
      >
        <span class="material-icons" [ngClass]="{ 'text-gray-500': isReadonly, 'text-xl': miniselect }">close</span>
      </button>
      <button
        type="button"
        (click)="handleClick()"
        [title]="isOpen ? 'Comprimir' : 'Expandir'"
        class="flex items-center rounded bg-gray-100 p-0.5 transition-all duration-150 disabled:bg-gray-100 hover:bg-gray-200 disabled:hover:bg-gray-100"
      >
        <span class="material-icons" [ngClass]="{ 'text-gray-500': isReadonly, 'text-xl': miniselect }">{{
          isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
        }}</span>
      </button>
    </div>
  </div>

  <div
    class="absolute z-[110] max-h-60 w-full rounded rounded-t-none border border-black bg-gray-100"
    [ngClass]="{ 'hidden max-h-0': !isOpen }"
  >
    <div class="my-1 p-1">
      <input
        #searchInput
        id="search-options"
        [name]="id"
        placeholder="Digite para pesquisar"
        title="Digite para pesquisar"
        class="w-full rounded border-2 border-gray-100 bg-gray-100 py-2 px-4 text-dark-blue-puple outline-gray-300"
        [formControl]="searchControl"
        (focus)="isOpen = true"
        autocomplete="off"
        (keydown.enter)="$event.preventDefault()"
      />
    </div>
    <ul
      id="list-options"
      class="max-h-44 w-full overflow-y-auto rounded-b pb-2 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300"
    >
      <li id="option-empty" class="bg-gray-100 px-4 py-2" *ngIf="filteredOptions.length === 0">Nenhum dado encontrado</li>
      <li
        id="option"
        class="flex cursor-pointer items-center px-4 py-2 transition-all duration-200 hover:bg-gray-300"
        [ngClass]="{ 'bg-gray-200': option?.value === value?.value }"
        *ngFor="let option of filteredOptions"
        (click)="handleClickOption(option)"
      >
        <span *ngIf="option?.value === value?.value" class="material-icons mr-2 text-xl text-gray-900">check</span>
        <span class="text-gray-800" [ngClass]="{ 'text-dark-blue-puple': option?.value === value?.value }">{{ option.label }} </span>
      </li>
    </ul>
  </div>

  <div>
    <span class="span-required" *ngIf="control.hasError('required') && submitted">{{
      miniselect ? '* Obrigatório' : '* Campo obrigatório'
    }}</span>
  </div>
</fieldset>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html'
})
export class PieChartComponent {
  private instance: Highcharts.Chart;
  private pieSerie: Highcharts.SeriesPieOptions;
  _highcharts = Highcharts;
  chartOptions: Highcharts.Options;
  isLoading = true;
  updateFlag = false;

  @Input() data: any;
  @Input() title: string;
  @Input() subtitle: string;

  @Output() onclick = new EventEmitter<Highcharts.SeriesClickEventObject>();

  constructor() {
    this.chartOptions = {
      series: [],
      plotOptions: {
        pie: {
          showInLegend: true,
          cursor: 'pointer',
          dataLabels: [
            {
              enabled: true
            }
          ]
        }
      },
      chart: {
        type: 'pie'
      },
      tooltip: {
        pointFormat: '{series.name} <b> {point.percentage:.1f}% ({point.y})'
      },
      title: {
        text: this.title
      },
      subtitle: {
        text: this.subtitle
      },
      credits: {
        enabled: false
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        margin: 50,
        maxHeight: 100,
        itemStyle: {
          fontSize: '12px'
        },
        navigation: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: '#333',
            fontSize: '12px'
          }
        }
      }
    };
  }

  onInstance(chart: Highcharts.Chart) {
    this.instance = chart;
  }

  updateData(data: Highcharts.SeriesPieOptions[]) {
    this.isLoading = true;
    this.handleClear();
    data.forEach((value) => this.instance.addSeries(value));
    this.updateFlag = true;
    this.isLoading = false;
  }

  handleClear() {
    while (this.instance.series.length) {
      this.instance.series[0].remove();
    }
  }
}

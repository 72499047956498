import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MessageService } from '@core/services';
import { parseISO } from 'date-fns';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { HeaderOptionsTypes } from '../table';

export type Filter = {
  column: string;
  value: string;
  operator?: string;
};

export enum OperatorInputFilter {
  gte = '__maior_igual',
  lte = '__menor_igual',
  entre = 'entre'
}

const INPUT_FIELD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputFilterComponent),
  multi: true
};

@Component({
  selector: 'input-filter',
  templateUrl: 'input-filter.component.html',
  styleUrls: ['input-filter.style.scss'],
  standalone: true,
  imports: [NgxMaskDirective, ReactiveFormsModule, CommonModule, MatIconModule, MatRadioModule, MatMenuModule, MatChipsModule],
  providers: [INPUT_FIELD_VALUE_ACCESSOR, provideNgxMask()]
})
export class InputFilterComponent implements ControlValueAccessor, OnInit {
  @Input() attribute: string;
  @Input() enableFilter = true;
  @Input() valueType?: HeaderOptionsTypes;
  @Input() filtrosCache?: Map<string, Filter[]>;

  @Output() fieldToSearch? = new EventEmitter<Filter[]>();
  @Output() fieldToDelete? = new EventEmitter();

  @ViewChild('searchInput') searchInput: ElementRef;

  isFilterApplied = false;

  filterForm: FormGroup;

  valueFilter: string;
  otherValue: string;
  valueOperator: string;
  inputType: string;

  initialStateCache: { value: string; operator: string; otherValue?: string };
  datePipe = new DatePipe('pt');

  maxPattern: string;

  private _innerValue: string;

  private OperatorInputFilterValue = new Map<string, string>([
    [OperatorInputFilter.gte, '≥'],
    [OperatorInputFilter.lte, '≤'],
    [OperatorInputFilter.entre, 'a']
  ]);

  constructor(private readonly formBuilder: FormBuilder, private readonly messageService: MessageService) {}

  get value() {
    return this._innerValue;
  }

  set value(v: string) {
    if (this._innerValue !== v) {
      this._innerValue = v;
      this.onChangeCb(v);
    }
  }

  ngOnInit() {
    this.handleInitForm();
    this.handleLoadCache();
    this.inputType = this._getInputType(this.valueType);
    this.checkMax();
    this.enableFilter = this.enableFilter !== undefined ? this.enableFilter : true;
  }

  handleOpenFilter() {
    if (this.enableFilter) {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 50);
    }
  }

  handleInitForm() {
    this.filterForm = this.formBuilder.group({ value: [''], operator: [null], otherValue: [''] });
    this.valueFilter = null;
    this.valueOperator = null;
    this.isFilterApplied = false;
  }

  handleLoadCache() {
    const filters = this.filtrosCache.get(this.attribute);
    if (filters) {
      if (filters.length > 1) {
        const [otherFilter, filter] = filters;
        const valueCache = this._getInputType(this.valueType) === 'number' ? filter.value?.replace('.', ',') : filter.value;
        const otherFilterValueCache =
          this._getInputType(this.valueType) === 'number' ? otherFilter.value?.replace('.', ',') : otherFilter.value;

        this.filterForm = this.formBuilder.group({ value: [valueCache], operator: ['entre'], otherValue: [otherFilterValueCache] });
        this.valueFilter = valueCache ?? null;
        this.otherValue = otherFilterValueCache;
        this.valueOperator = this._getOperador('entre') ?? null;
        this.isFilterApplied = this.valueFilter ? true : false;
      } else {
        const filter = Array.isArray(filters) ? filters[0] : filters;
        const valueCache = this._getInputType(this.valueType) === 'number' ? filter.value?.replace('.', ',') : filter.value;
        const operatorCache = filter.operator;

        this.filterForm = this.formBuilder.group({ value: [valueCache], operator: [operatorCache], otherValue: '' });
        this.valueFilter = valueCache ?? null;
        this.valueOperator = this._getOperador(operatorCache) ?? null;
        this.isFilterApplied = this.valueFilter ? true : false;
      }
    }
    this.initialStateCache = this.filterForm.value;
  }

  handleFilter() {
    const { value, operator, otherValue } = this.filterForm.value;
    const response: Filter[] = [];

    if (operator === 'entre') {
      if (this._getInputType(this.valueType) === 'number') {
        if (Number(otherValue.toString().replace(',', '.')) > Number(value.toString().replace(',', '.'))) {
          this.messageService.showError('O valor inicial deve ser menor o valor final', 'Erro ao selecionar valores');
          return;
        }
      } else {
        if (parseISO(otherValue) > parseISO(value)) {
          this.messageService.showError('A data inicial deve ser anterior a data final', 'Erro ao selecionar as datas');
          return;
        }
      }

      response.push({ column: this.attribute, value: otherValue?.replace(',', '.') || null, operator: OperatorInputFilter.gte });
      response.push({ column: this.attribute, value: value?.replace(',', '.') || null, operator: OperatorInputFilter.lte });
    } else {
      response.push({ column: this.attribute, value: value?.replace(',', '.') || null, operator: operator || '' });
    }
    this.fieldToSearch.emit(response);
  }

  handleCleanFilter() {
    this.handleInitForm();
    this.fieldToDelete.emit(this.attribute);
  }

  onChangeCb: (_: any) => void = () => {};
  onTouchedCb: (_: any) => void = () => {};

  writeValue(value: any): void {
    if (value !== this._innerValue) {
      if (value !== null && this.valueType === HeaderOptionsTypes.NUMBER) {
        this.value = new DecimalPipe('pt').transform(Number(value.toString().replace(',', '.')), '1.3-3');
      } else {
        this.value = value;
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  hasFilterChanges() {
    return JSON.stringify(this.initialStateCache) !== JSON.stringify(this.filterForm.value);
  }

  checkMax() {
    if (this.valueType === HeaderOptionsTypes.DATE_TIME) {
      this.maxPattern = '9999-12-31T23:59';
    } else if (this.valueType === HeaderOptionsTypes.DATE_TIME_SECONDS) {
      this.maxPattern = '9999-12-31T23:59:59';
    } else if (this.valueType === HeaderOptionsTypes.DATE_TIME_MILLISECONDS) {
      this.maxPattern = '9999-12-31T23:59:59.999';
    } else if (this.valueType === HeaderOptionsTypes.DATE) {
      this.maxPattern = '9999-12-31';
    } else {
      this.maxPattern = null;
    }
  }

  private _getOperador(operator: string): string {
    return this.OperatorInputFilterValue.get(operator);
  }

  private _getInputType(type: HeaderOptionsTypes) {
    if (type === HeaderOptionsTypes.DATE_TIME) return 'datetime-local';
    if (type === HeaderOptionsTypes.DATE_TIME_MILLISECONDS) return 'datetime-local';
    if (type === HeaderOptionsTypes.DATE_TIME_SECONDS) return 'datetime-local';
    if (type === HeaderOptionsTypes.DATE) return 'date';
    if (type === HeaderOptionsTypes.TIME) return 'time';
    if (type === HeaderOptionsTypes.NUMBER) return 'number';
    return 'text';
  }
}

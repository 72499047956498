<fieldset (clickOutside)="handleClickOutside()" class="relative my-4 w-full disabled:bg-transparent" [disabled]="isReadonly">
  <div
    class="flex max-h-44 w-full items-center justify-between rounded border px-4 py-2 hover:cursor-pointer"
    [ngClass]="{ 'rounded-b-none border-b-0': isOpen, 'border-gray-300': isReadonly, 'border-gray-700': !isReadonly }"
    (click)="handleClick()"
  >
    <label
      class="transition-all duration-200"
      [ngClass]="{
        'text-gray-300 hover:cursor-default': isReadonly,
        'pointer-events-none absolute -top-2.5 left-2 bg-gray-100 px-2 text-sm font-medium text-dark-blue-puple hover:cursor-default':
          values.length > 0,
        'top-2.5 w-full text-base font-normal text-dark-blue-puple hover:cursor-pointer': values.length === 0
      }"
      >{{ label }}</label
    >

    <ul
      *ngIf="values.length > 0"
      id="list-options-selected"
      class="grid max-h-40 w-full grid-cols-2 overflow-y-auto scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 lg:grid-cols-1"
      [ngClass]="{ classSelectedOptions: classSelectedOptions, 'grid-cols-1': values.length < 2 }"
    >
      <li
        id="option-selected"
        class="my-2 flex w-11/12 flex-row items-center justify-between gap-x-2 rounded-md bg-gray-300 px-4 py-2"
        [title]="option.label"
        *ngFor="let option of values"
      >
        <span class="w-11/12 truncate">{{ option.selectedLabel ?? option.label }}</span>
        <button
          class="pointer-events-auto flex w-1/12 items-center justify-end text-gray-800 hover:cursor-pointer hover:opacity-90"
          title="Remover"
          (click)="handleClickRemoveOption(option)"
        >
          <span class="material-icons">cancel</span>
        </button>
      </li>
    </ul>

    <div class="flex items-center gap-2">
      <button
        *ngIf="values.length > 0"
        type="button"
        title="Limpar seleção"
        (click)="handleClearSelection()"
        [disabled]="isReadonly"
        class="flex items-center rounded bg-gray-100 p-0.5 transition-all duration-150 disabled:bg-gray-100 hover:bg-gray-200 disabled:hover:bg-gray-100"
      >
        <span class="material-icons">close</span>
      </button>
      <button
        type="button"
        [title]="isOpen ? 'Comprimir' : 'Expandir'"
        class="flex items-center rounded bg-gray-100 p-0.5 transition-all duration-150 disabled:bg-gray-100 hover:bg-gray-200 disabled:hover:bg-gray-100"
      >
        <span class="material-icons" [ngClass]="{ 'text-gray-500': isReadonly }">
          {{ isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
        </span>
      </button>
    </div>
  </div>

  <div
    class="absolute left-0 z-50 max-h-64 w-full rounded rounded-t-none border border-black bg-gray-100 transition-all duration-300"
    [ngClass]="{ 'hidden max-h-0': !isOpen }"
  >
    <div class="my-1 p-1">
      <input
        #searchInput
        id="search-options"
        [name]="id"
        placeholder="Digite para pesquisar"
        title="Digite para pesquisar"
        class="w-full rounded border-2 border-gray-100 py-2 px-4 text-dark-blue-puple outline-gray-300"
        [formControl]="searchControl"
        (focus)="isOpen = true"
        autocomplete="off"
        (keydown.enter)="$event.preventDefault()"
      />
    </div>
    <ul
      id="list-options"
      class="max-h-44 w-full overflow-y-auto rounded-b pb-2 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300"
    >
      <li id="option-empty" class="px-4 py-2" *ngIf="filteredOptions.length === 0">Nenhum dado encontrado</li>
      <li
        id="option"
        class="flex cursor-pointer items-center px-4 py-2 transition-all duration-200 hover:bg-gray-100"
        [ngClass]="{ 'bg-gray-200': values.includes(option) }"
        *ngFor="let option of filteredOptions"
        (click)="handleClickOption(option)"
      >
        <span *ngIf="values.includes(option)" class="material-icons mr-1 text-xl text-dark-blue-puple">check</span>
        <span class="text-gray-800" [ngClass]="{ 'text-dark-blue-puple': values.includes(option) }">{{ option.label }} </span>
      </li>
    </ul>
  </div>

  <div>
    <span class="span-required" *ngIf="control.hasError('required') && submitted">* Campo obrigatório</span>
  </div>
</fieldset>

import { Injectable } from '@angular/core';
import { SidebarModuleRoutingType, SidebarModulesName } from '@core/components';
import { EPermissions, ERoutes } from '@core/enums';
import { PermissionService } from '@core/services/permission.service';
import { compareStrings } from '@core/utils';
import { EMedicaoFiscal } from '@features/relatorio-medicao-fiscal/utils';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidebarService {
  isSidebarOpen = new BehaviorSubject<boolean>(false);

  //dashboard
  private isVisible_Dashboard = false;

  //Gestão da Produção
  private isVisible_GestaoProducao = false;

  private isVisible_ConfigSuperficiePoco = false;
  private isVisible_MalhaInjecao = false;
  private isVisible_GestaoProducaoCadastro = false;
  private isVisible_Campos = false;
  private isVisible_Escoamentos = false;
  private isVisible_Estacoes = false;
  private isVisible_Pocos = false;
  private isVisible_Nos = false;
  private isVisible_Satelites = false;
  private isVisible_RamaisEletricos = false;
  private isVisible_Clusters = false;

  private isVisible_GestaoProducaoMedicao = false;
  private isVisible_GestaoProducaoMedicaoFiscal = false;
  private isVisible_MedicoesFiscaisLinhaGas = false;
  private isVisible_MedicoesFiscaisLinhaOleo = false;
  private isVisible_MedicoesFiscaisTanque = false;

  private isVisible_Captacoes = false;
  private isVisible_Medicao_Operacional_Queima = false;
  private isVisible_TestesPoco = false;

  // Gestão de Ocorrências
  private isVisible_GestaoOcorrencia = false;

  private isVisible_GestaoOcorrenciaCadastro = false;
  private isVisible_GrupoMotivoOcorrencia = false;
  private isVisible_GrupoProvidencia = false;
  private isVisible_TipoResponsavel = false;
  private isVisible_ProvidenciaOcorrencia = false;
  private isVisible_MotivoOcorrencia = false;
  private isVisible_ResponsavelOcorrencia = false;
  private isVisible_Ocorrencia = false;
  private isVisible_Ocorrencia_Injetores = false;
  private isVisible_agrupamento_motivo_providencia_responsavel = false;
  private isVisible_TestesInjecao = false;

  // Fechamento de Produção
  private isVisible_BMP = false;
  private isVisible_ProducaoDiaria = false;
  private isVisible_FechamentoProducao = false;

  // Gestão de Prazos
  private isVisible_GestaoPrazos = false;
  private isVisible_GestaoPrazoTestePocoTeste = false;
  private isVisible_GestaoPrazoInstrumento = false;

  //Reservatório
  private isVisible_ReservatorioCadastro = false;
  private isVisible_Reservatorios = false;
  private isVisible_RGO = false;
  private isVisible_ZonasProdutoras = false;

  private isVisible_AssociarZonas = false;
  private isVisible_Potencial = false;

  private isVisible_Reservatorio = false;

  //Equipamento
  private isVisible_laboratorio_equipamentos = false;
  private isVisible_Equipamento = false;
  private isVisible_instrumentos = false;

  private isVisible_GestaoLacres = false;
  private isVisible_Lotes = false;
  private isVisible_Lacres = false;
  private isVisible_Fornecedores = false;

  private isVisible_superficie_equipamentos = false;
  private isVisible_Cabecote = false;
  private isVisible_Transformador = false;
  private isVisible_UnidadesBombeio = false;

  private isVisible_Tags = false;
  private isVisible_PontosMedicao = false;

  private isVisible_Fabricantes = false;

  // Relatórios
  private isVisible_Relatorios = false;
  private isVisible_RelatorioTransferenciaFiscaisTanque = false;
  private isVisible_Relatorios_Injecoes_Diarias = false;
  private isVisible_RelatorioMedicaoFiscal = false;
  private isVisible_RelatorioMedicaoOperacionalQueima = false;
  private isVisible_Relatorios_Analitico_Injecoes_Diarias = false;
  private isVisible_Relatorios_Sintetico_Injecoes_Diarias = false;
  private isVisible_RelatorioMTBF = false;
  isVisible_RelatorioPerda = false;
  private isVisible_RelatorioProducoesDiarias = false;

  //Laboratório
  private isVisible_Amostras = false;
  private isVisible_BSWs = false;
  private isVisible_Cromatografias = false;
  private isVisible_Densidades = false;
  private isVisible_FERSs = false;
  private isVisible_H2Ss = false;
  private isVisible_Salinidades = false;

  private isVisible_Laboratorio = false;

  //Administração
  private isVisible_AdministracaoCadastro = false;
  private isVisible_Cargos = false;
  private isVisible_Colaboradores = false;
  private isVisible_Empresas = false;
  private isVisible_Perfis = false;
  private isVisible_Usuarios = false;

  private isVisible_Administracao = false;

  constructor(private readonly permissionService: PermissionService) {}

  createSideBar(): SidebarModuleRoutingType[] {
    return [
      {
        id: 'dashboard',
        moduleName: SidebarModulesName.DASHBOARD,
        iconName: 'web',
        hasTopics: false,
        hasSubtopics: false,
        routeName: ERoutes.HOME,
        isVisible: this.isVisible_Dashboard
      },
      {
        id: 'gestao-producao',
        moduleName: SidebarModulesName.GESTAO_DA_PRODUCAO,
        iconName: 'gas_meter',
        hasTopics: true,
        hasSubtopics: true,
        isOpen: false,
        isVisible: this.isVisible_GestaoProducao,
        topics: [
          {
            id: 'gestao-producao-cadastro',
            moduleName: SidebarModulesName.CADASTRO,
            iconName: '',
            hasTopics: false,
            hasSubtopics: true,
            isOpen: false,
            isVisible: this.isVisible_GestaoProducaoCadastro,
            subtopics: [
              {
                id: 'gestao-producao-cadastro-campos',
                name: 'Campos',
                routeName: ERoutes.CAMPO,
                isVisible: this.isVisible_Campos
              },
              {
                id: 'gestao-producao-cadastro-cluster',
                name: 'Clusters',
                routeName: ERoutes.CLUSTER,
                isVisible: this.isVisible_Clusters
              },
              {
                id: 'gestao-producao-cadastro-escoamentos',
                name: 'Escoamentos',
                routeName: ERoutes.ESCOAMENTO,
                isVisible: this.isVisible_Escoamentos
              },
              {
                id: 'gestao-producao-cadastro-estacoes',
                name: 'Estações',
                routeName: ERoutes.ESTACAO,
                isVisible: this.isVisible_Estacoes
              },
              {
                id: 'gestao-producao-cadastro-no',
                name: 'Nós',
                routeName: ERoutes.NO,
                isVisible: this.isVisible_Nos
              },
              {
                id: 'gestao-producao-cadastro-pocos',
                name: 'Poços',
                routeName: ERoutes.POCO,
                isVisible: this.isVisible_Pocos
              },
              {
                id: 'gestao-producao-cadastro-ramalEletrico',
                name: 'Ramais Elétricos',
                routeName: ERoutes.RAMAL_ELETRICO,
                isVisible: this.isVisible_RamaisEletricos
              },
              {
                id: 'gestao-producao-cadastro-satelite',
                name: 'Satélites',
                routeName: ERoutes.SATELITE,
                isVisible: this.isVisible_Satelites
              }
            ]
          },
          {
            id: 'gestao-producao-medicao',
            moduleName: SidebarModulesName.MEDICAO,
            iconName: '',
            hasTopics: true,
            hasSubtopics: false,
            isOpen: false,
            isVisible: this.isVisible_GestaoProducaoMedicao,
            topics: [
              {
                id: 'gestao-producao-medicao-fiscal',
                moduleName: SidebarModulesName.FISCAL,
                iconName: '',
                hasTopics: false,
                hasSubtopics: true,
                isOpen: false,
                isVisible: this.isVisible_GestaoProducaoMedicaoFiscal,
                subtopics: [
                  {
                    id: 'gestao-producao-medicao-fiscal-medicoes-fiscais-linha-gas',
                    name: 'Linha | Gás',
                    routeName: ERoutes.MEDICAO_FISCAL_LINHA_GAS,
                    isVisible: this.isVisible_MedicoesFiscaisLinhaGas
                  },
                  {
                    id: 'gestao-producao-medicao-fiscal-medicoes-fiscais-linha-oleo',
                    name: 'Linha | Óleo',
                    routeName: ERoutes.MEDICAO_FISCAL_LINHA_OLEO,
                    isVisible: this.isVisible_MedicoesFiscaisLinhaOleo
                  },
                  {
                    id: 'gestao-producao-medicao-fiscal-medicoes-fiscais-tanque',
                    name: 'Tanque',
                    routeName: ERoutes.CICLOS,
                    isVisible: this.isVisible_MedicoesFiscaisTanque
                  }
                ]
              }
            ]
          }
        ],
        subtopics: [
          {
            id: 'gestao-producao-captacoes',
            name: 'Captações de Água',
            routeName: ERoutes.CAPTACAO,
            isVisible: this.isVisible_Captacoes
          },
          {
            id: 'gestao-producao-configuracao-superficie-poco',
            name: 'Configuração de Superfície de Poço',
            routeName: ERoutes.CONFIGURACAO_SUPERFICIE_POCO,
            isVisible: this.isVisible_ConfigSuperficiePoco
          },
          {
            id: 'gestao-producao-malha-injecao',
            name: 'Malha de Injeção',
            routeName: ERoutes.MALHA_INJECAO,
            isVisible: this.isVisible_MalhaInjecao
          },
          {
            id: 'gestao-producao-testes-injecao',
            name: 'Testes de Injeção',
            routeName: ERoutes.TESTE_INJECAO,
            isVisible: this.isVisible_TestesInjecao
          },
          {
            id: 'gestao-producao-testes-poco',
            name: 'Testes de Poço',
            routeName: ERoutes.TESTE_POCO,
            isVisible: this.isVisible_TestesPoco
          },
          {
            id: 'medicao-operacional-queima-gas',
            name: 'Medição Operacional de Queima de Gás',
            routeName: ERoutes.MEDICOES_OPERACIONAIS_QUEIMA,
            isVisible: this.isVisible_Medicao_Operacional_Queima
          }
        ]
      },
      {
        id: 'gestao-ocorrencia',
        moduleName: SidebarModulesName.GESTAO_DE_OCORRENCIA,
        iconName: 'airline_stops',
        hasTopics: true,
        hasSubtopics: true,
        isOpen: false,
        isVisible: this.isVisible_GestaoOcorrencia,
        topics: [
          {
            id: 'gestao-ocorrencia-cadastro',
            moduleName: SidebarModulesName.CADASTRO,
            iconName: '',
            hasTopics: false,
            hasSubtopics: true,
            isOpen: false,
            isVisible: this.isVisible_GestaoOcorrenciaCadastro,
            subtopics: [
              {
                id: 'gestao-ocorrencia-cadastro-grupoMotivoOcorrencia',
                name: 'Grupos de Motivos',
                routeName: ERoutes.GRUPO_MOTIVO_OCORRENCIA,
                isVisible: this.isVisible_GrupoMotivoOcorrencia
              },
              {
                id: 'gestao-ocorrencia-cadastro-grupoProvidencia',
                name: 'Grupos de Providências',
                routeName: ERoutes.GRUPO_PROVIDENCIA_OCORRENCIA,
                isVisible: this.isVisible_GrupoProvidencia
              },
              {
                id: 'gestao-ocorrencia-cadastro-tipoResponsavel',
                name: 'Tipos de Responsáveis',
                routeName: ERoutes.TIPO_RESPONSAVEL_OCORRENCIA,
                isVisible: this.isVisible_TipoResponsavel
              },
              {
                id: 'gestao-ocorrencia-cadastro-motivoOcorrencia',
                name: 'Motivos',
                routeName: ERoutes.MOTIVO_OCORRENCIA,
                isVisible: this.isVisible_MotivoOcorrencia
              },
              {
                id: 'gestao-ocorrencia-cadastro-providencia',
                name: 'Providências',
                routeName: ERoutes.PROVIDENCIA_OCORRENCIA,
                isVisible: this.isVisible_ProvidenciaOcorrencia
              },
              {
                id: 'gestao-ocorrencia-cadastro-responsavel',
                name: 'Responsáveis',
                routeName: ERoutes.RESPONSAVEL_OCORRENCIA,
                isVisible: this.isVisible_ResponsavelOcorrencia
              },
              {
                id: 'gestao-ocorrencia-cadastro-motivoOcorrencia',
                name: 'Agrupamento de Motivo, Ocorrência e Responsável',
                routeName: ERoutes.AGRUPAMENTO_MOTIVO_PROVIDENCIA_RESPONSAVEL_OCORRENCIA,
                isVisible: this.isVisible_agrupamento_motivo_providencia_responsavel
              }
            ]
          }
        ],
        subtopics: [
          {
            id: 'gestao-ocorrencia-ocorrencia-injetores',
            name: 'Ocorrências Injetores',
            routeName: ERoutes.OCORRENCIA_INJETORES,
            isVisible: this.isVisible_Ocorrencia
          },
          {
            id: 'gestao-ocorrencia-ocorrencia',
            name: 'Ocorrências Produtores',
            routeName: ERoutes.OCORRENCIA_PRODUTORES,
            isVisible: this.isVisible_Ocorrencia
          }
        ]
      },
      {
        id: 'fechamento-producao',
        moduleName: SidebarModulesName.FECHAMENTO_PRODUCAO,
        iconName: 'fact_check',
        hasTopics: false,
        hasSubtopics: true,
        isOpen: false,
        isVisible: this.isVisible_FechamentoProducao,
        subtopics: [
          {
            id: 'fechamento-producao-bmp',
            name: 'BMP',
            routeName: ERoutes.BMP,
            isVisible: this.isVisible_BMP
          },
          {
            id: 'fechamento-producao-producao-diaria',
            name: 'Produções Diárias',
            routeName: ERoutes.PRODUCAO_DIARIA,
            isVisible: this.isVisible_ProducaoDiaria
          }
        ]
      },
      {
        id: 'relatorios',
        moduleName: SidebarModulesName.RELATORIOS,
        iconName: 'description',
        hasSubtopics: true,
        hasTopics: true,
        isOpen: false,
        isVisible: this.isVisible_Relatorios,
        topics: [
          {
            id: 'relatorios-injecoes-diarias',
            moduleName: SidebarModulesName.RELATORIOS_INJECOES_DIARIAS,
            iconName: '',
            hasTopics: false,
            hasSubtopics: true,
            isOpen: false,
            isVisible: this.isVisible_Relatorios_Injecoes_Diarias,
            subtopics: [
              {
                id: 'relatorios-analitico-injecoes-diarias',
                name: 'Analítico',
                routeName: ERoutes.RELATORIO_ANALITICO_INJECAO_DIARIA,
                isVisible: this.isVisible_Relatorios_Analitico_Injecoes_Diarias
              },
              {
                id: 'relatorios-sintetico-injecoes-diarias',
                name: 'Sintético',
                routeName: ERoutes.RELATORIO_SINTETICO_INJECAO_DIARIA,
                isVisible: this.isVisible_Relatorios_Sintetico_Injecoes_Diarias
              }
            ]
          },
          {
            id: 'relatorios-perda',
            isVisible: this.isVisible_RelatorioPerda,
            moduleName: SidebarModulesName.PERDAS,
            hasSubtopics: true,
            hasTopics: false,
            iconName: '',
            subtopics: [
              {
                id: 'relatorios-perda-injetores',
                isVisible: this.isVisible_RelatorioPerda,
                name: 'Injetores',
                routeName: `${ERoutes.RELATORIO}/${ERoutes.PERDA}/${ERoutes.INJETORES}`
              },
              {
                id: 'relatorios-perda-produtores',
                isVisible: this.isVisible_RelatorioPerda,
                name: 'Produtores',
                routeName: `${ERoutes.RELATORIO}/${ERoutes.PERDA}/${ERoutes.PRODUTORES}`
              }
            ]
          },
          {
            id: 'relatorios-medicao-fiscal',
            moduleName: SidebarModulesName.MEDICAO_FISCAL,
            iconName: '',
            hasTopics: false,
            hasSubtopics: true,
            isOpen: false,
            isVisible: this.isVisible_RelatorioMedicaoFiscal,
            subtopics: [
              {
                id: 'relatorios-medicao-fiscal-linha',
                name: 'Linha',
                routeName: `${ERoutes.RELATORIO}/${ERoutes.MEDICAO_FISCAL}/${EMedicaoFiscal.LINHA}`,
                isVisible: this.isVisible_RelatorioMedicaoFiscal
              },
              {
                id: 'relatorios-medicao-fiscal-tanque',
                name: 'Tanque',
                routeName: `${ERoutes.RELATORIO}/${ERoutes.MEDICAO_FISCAL}/${EMedicaoFiscal.TANQUE}`,
                isVisible: this.isVisible_RelatorioMedicaoFiscal
              }
            ]
          }
        ],
        subtopics: [
          {
            id: 'relatorios-medicoes-operacionais-queima',
            isVisible: this.isVisible_RelatorioMedicaoOperacionalQueima,
            name: 'Medições Operacionais Queima',
            routeName: `${ERoutes.RELATORIO}/${ERoutes.MEDICOES_OPERACIONAIS_QUEIMA}`
          },
          {
            id: 'relatorios-transferencia-fiscais-tanque',
            isVisible: this.isVisible_RelatorioTransferenciaFiscaisTanque,
            name: 'Transferências Fiscais de Tanque',
            routeName: `${ERoutes.RELATORIO}/${ERoutes.TRANSFERENCIA_FISCAL_TANQUE}`
          },
          {
            id: 'relatorios-mtbf',
            isVisible: this.isVisible_RelatorioMTBF,
            name: 'MTBF',
            routeName: `${ERoutes.RELATORIO}/${ERoutes.MTBF}`
          },
          {
            id: 'relatorios-producao-diaria',
            name: 'Produções Diárias',
            routeName: `${ERoutes.RELATORIO}/${ERoutes.PRODUCAO_DIARIA}`,
            isVisible: this.isVisible_RelatorioProducoesDiarias
          }
        ]
      },
      {
        id: 'gestao-prazos',
        moduleName: SidebarModulesName.GESTAO_DE_PRAZOS,
        iconName: 'hourglass_bottom',
        hasSubtopics: true,
        hasTopics: false,
        isOpen: false,
        isVisible: this.isVisible_GestaoPrazos,
        subtopics: [
          {
            id: 'gestao-prazos-teste-poco-teste',
            isVisible: this.isVisible_GestaoPrazoTestePocoTeste,
            name: 'Teste de Poço',
            routeName: `${ERoutes.GESTAO_PRAZOS}/${ERoutes.TESTE_POCO}/${ERoutes.TESTE}`
          },
          {
            id: 'gestao-prazos-instrumento',
            isVisible: this.isVisible_GestaoPrazoInstrumento,
            name: 'Calibração de Instrumentos',
            routeName: `${ERoutes.GESTAO_PRAZOS}/${ERoutes.INSTRUMENTO}`
          }
        ]
      },
      {
        id: 'reservatorio',
        moduleName: SidebarModulesName.RESERVATORIO,
        iconName: 'oil_barrel',
        hasTopics: true,
        hasSubtopics: true,
        isOpen: false,
        isVisible: this.isVisible_Reservatorio,
        topics: [
          {
            id: 'reservatorio-cadastro',
            moduleName: SidebarModulesName.CADASTRO,
            iconName: '',
            hasTopics: false,
            hasSubtopics: true,
            isOpen: false,
            isVisible: this.isVisible_ReservatorioCadastro,
            subtopics: [
              {
                id: 'reservatorio-cadastro-rgos',
                name: 'RGOs',
                isVisible: this.isVisible_RGO,
                routeName: ERoutes.RGO
              },
              {
                id: 'reservatorio-cadastro-reservatorios',
                name: 'Reservatórios',
                routeName: ERoutes.RESERVATORIO,
                isVisible: this.isVisible_Reservatorios
              },
              {
                id: 'reservatorio-cadastro-zonas-produtoras',
                name: 'Zonas Produtoras',
                routeName: ERoutes.ZONA_PRODUTORA,
                isVisible: this.isVisible_ZonasProdutoras
              }
            ]
          }
        ],
        subtopics: [
          {
            id: 'reservatorio-potencial',
            name: 'Potenciais',
            routeName: ERoutes.POTENCIAL,
            isVisible: this.isVisible_Potencial
          },
          {
            id: 'reservatorio-cadastro-associar-zonas',
            name: 'Associar Zonas',
            routeName: ERoutes.POCO_ZONA,
            isVisible: this.isVisible_AssociarZonas
          }
        ]
      },
      {
        id: 'equipamento',
        moduleName: SidebarModulesName.EQUIPAMENTO,
        iconName: 'construction',
        hasTopics: true,
        hasSubtopics: true,
        isOpen: false,
        isVisible: this.isVisible_Equipamento,
        topics: [
          {
            id: 'equipamento-instrumento',
            moduleName: SidebarModulesName.INSTRUMENTOS,
            iconName: '',
            hasTopics: false,
            hasSubtopics: true,
            isOpen: false,
            isVisible: this.isVisible_instrumentos,
            subtopics: [
              {
                id: 'equipamento-instrumento-computador-vazao',
                name: 'Computador de Vazão',
                routeName: ERoutes.INSTRUMENTO_COMPUTADOR_VAZAO,
                isVisible: this.isVisible_instrumentos
              },
              {
                id: 'equipamento-instrumento-elemento-temperatura',
                name: 'Elemento de Temperatura',
                routeName: ERoutes.INSTRUMENTO_ELEMENTO_TEMPERATURA,
                isVisible: this.isVisible_instrumentos
              },
              {
                id: 'equipamento-instrumento-manometro',
                name: 'Manômetro',
                routeName: ERoutes.INSTRUMENTO_MANOMETRO,
                isVisible: this.isVisible_instrumentos
              },
              {
                id: 'equipamento-instrumento-medidor-vazao-coriolis',
                name: 'Medidor de Vazão Coriolis',
                routeName: ERoutes.INSTRUMENTO_MEDIDOR_VAZAO_CORIOLIS,
                isVisible: this.isVisible_instrumentos
              },
              {
                id: 'equipamento-instrumento-medidor-vazao-rotativo',
                name: 'Medidor de Vazão Rotativo',
                routeName: ERoutes.INSTRUMENTO_MEDIDOR_VAZAO_ROTATIVO,
                isVisible: this.isVisible_instrumentos
              },
              {
                id: 'equipamento-instrumento-mvs',
                name: 'MVS',
                routeName: ERoutes.INSTRUMENTO_MVS,
                isVisible: this.isVisible_instrumentos
              },
              {
                id: 'equipamento-instrumento-placa-orificio',
                name: 'Placa de Orifício',
                routeName: ERoutes.INSTRUMENTO_PLACA_ORIFICIO,
                isVisible: this.isVisible_instrumentos
              },
              {
                id: 'equipamento-instrumento-regua',
                name: 'Régua',
                routeName: ERoutes.INSTRUMENTO_REGUA,
                isVisible: this.isVisible_instrumentos
              },
              {
                id: 'equipamento-instrumento-tanque',
                name: 'Tanque',
                routeName: ERoutes.INSTRUMENTO_TANQUE,
                isVisible: this.isVisible_instrumentos
              },
              {
                id: 'equipamento-instrumento-termometro',
                name: 'Termômetro',
                routeName: ERoutes.INSTRUMENTO_TERMOMETRO,
                isVisible: this.isVisible_instrumentos
              },
              {
                id: 'equipamento-instrumento-termometro-costado-tanque',
                name: 'Termômetro do Costado do Tanque',
                routeName: ERoutes.INSTRUMENTO_TERMOMETRO_COSTADO_TANQUE,
                isVisible: this.isVisible_instrumentos
              },
              {
                id: 'equipamento-instrumento-termometro-imersao-tanque',
                name: 'Termômetro de Imersão do Tanque',
                routeName: ERoutes.INSTRUMENTO_TERMOMETRO_IMERSAO_TANQUE,
                isVisible: this.isVisible_instrumentos
              },
              {
                id: 'equipamento-instrumento-transmissor-nivel',
                name: 'Transmissor de Nível',
                routeName: ERoutes.INSTRUMENTO_TRANSMISSOR_DE_NIVEL,
                isVisible: this.isVisible_instrumentos
              },
              {
                id: 'equipamento-instrumento-transmissor-diferencial-pressao',
                name: 'Transmissor de Pressão Diferencial',
                routeName: ERoutes.INSTRUMENTO_TRANSMISSOR_DIFERENCIAL_PRESSAO,
                isVisible: this.isVisible_instrumentos
              },
              {
                id: 'equipamento-instrumento-transmissor-pressao',
                name: 'Transmissor de Pressão',
                routeName: ERoutes.INSTRUMENTO_TRANSMISSOR_PRESSAO,
                isVisible: this.isVisible_instrumentos
              },
              {
                id: 'equipamento-instrumento-transmissor-temperatura',
                name: 'Transmissor de Temperatura',
                routeName: ERoutes.INSTRUMENTO_TRANSMISSOR_TEMPERATURA,
                isVisible: this.isVisible_instrumentos
              },
              {
                id: 'equipamento-instrumento-trecho-reto',
                name: 'Trecho Reto',
                routeName: ERoutes.INSTRUMENTO_TRECHO_RETO,
                isVisible: this.isVisible_instrumentos
              },
              {
                id: 'equipamento-instrumento-trena',
                name: 'Trena',
                routeName: ERoutes.INSTRUMENTO_TRENA,
                isVisible: this.isVisible_instrumentos
              },
              {
                id: 'equipamento-instrumento-turbidimetro',
                name: 'Turbidímetro',
                routeName: ERoutes.INSTRUMENTO_TURBIDIMETRO,
                isVisible: this.isVisible_instrumentos
              },
              {
                id: 'equipamento-instrumento-valvula',
                name: 'Válvula',
                routeName: ERoutes.INSTRUMENTO_VALVULA,
                isVisible: this.isVisible_instrumentos
              }
            ]
          },
          {
            id: 'equipamento-gestao-lacres',
            moduleName: SidebarModulesName.GESTAO_DE_LACRES,
            iconName: '',
            hasSubtopics: true,
            hasTopics: false,
            isOpen: false,
            isVisible: this.isVisible_GestaoLacres,
            subtopics: [
              {
                id: 'equipamento-gestao-lacres-geracao',
                name: 'Geração de Lacres',
                routeName: ERoutes.GESTAO_LACRES,
                isVisible: this.isVisible_Lotes
              },
              {
                id: 'equipamento-gestao-lacres-instalados-removidos',
                name: 'Instalação e Remoção de Lacres',
                routeName: `${ERoutes.GESTAO_LACRES}/${ERoutes.INSTALACAO_REMOCAO}`,
                isVisible: this.isVisible_Lacres
              },
              {
                id: 'equipamento-gestao-lacres-fornecedores',
                name: 'Fornecedores',
                routeName: `${ERoutes.FORNECEDORES}`,
                isVisible: this.isVisible_Fornecedores
              }
            ]
          },
          {
            id: 'equipamento-laboratorio',
            moduleName: SidebarModulesName.EQUIPAMENTO_LABORATORIO,
            iconName: '',
            hasTopics: false,
            hasSubtopics: true,
            isOpen: false,
            isVisible: this.isVisible_laboratorio_equipamentos,
            subtopics: [
              {
                id: 'equipamento-laboratorio-balanca',
                name: 'Balança',
                routeName: ERoutes.EQUIPAMENTO_BALANCA,
                isVisible: this.isVisible_laboratorio_equipamentos
              },
              {
                id: 'equipamento-laboratorio-banho-maria',
                name: 'Banho Maria',
                routeName: ERoutes.EQUIPAMENTO_BANHO_MARIA,
                isVisible: this.isVisible_laboratorio_equipamentos
              },
              {
                id: 'equipamento-laboratorio-bequer',
                name: 'Bequer',
                routeName: ERoutes.EQUIPAMENTO_BEQUER,
                isVisible: this.isVisible_laboratorio_equipamentos
              },
              {
                id: 'equipamento-laboratorio-bomba-vacuo',
                name: 'Bomba Vácuo',
                routeName: ERoutes.EQUIPAMENTO_BOMBA_VACUO,
                isVisible: this.isVisible_laboratorio_equipamentos
              },
              {
                id: 'equipamento-laboratorio-centrifuga',
                name: 'Centrífuga',
                routeName: ERoutes.EQUIPAMENTO_CENTRIFUGA,
                isVisible: this.isVisible_laboratorio_equipamentos
              },
              {
                id: 'equipamento-laboratorio-cilindro',
                name: 'Cilindro',
                routeName: ERoutes.EQUIPAMENTO_CILINDRO,
                isVisible: this.isVisible_laboratorio_equipamentos
              },
              {
                id: 'equipamento-laboratorio-cromatografo',
                name: 'Cromatógrafo',
                routeName: ERoutes.EQUIPAMENTO_CROMATOGRAFO,
                isVisible: this.isVisible_laboratorio_equipamentos
              },
              {
                id: 'equipamento-laboratorio-densimetro-digital',
                name: 'Densímetro Digital',
                routeName: ERoutes.EQUIPAMENTO_DENSIMETRO_DIGITAL,
                isVisible: this.isVisible_laboratorio_equipamentos
              },
              {
                id: 'equipamento-laboratorio-densimetro',
                name: 'Densímetro',
                routeName: ERoutes.EQUIPAMENTO_DENSIMETRO,
                isVisible: this.isVisible_laboratorio_equipamentos
              },
              {
                id: 'equipamento-laboratorio-estufa',
                name: 'Estufa',
                routeName: ERoutes.EQUIPAMENTO_ESTUFA,
                isVisible: this.isVisible_laboratorio_equipamentos
              },
              {
                id: 'equipamento-laboratorio-phmetro',
                name: 'PHmetro',
                routeName: ERoutes.EQUIPAMENTO_PHMETRO,
                isVisible: this.isVisible_laboratorio_equipamentos
              },
              {
                id: 'equipamento-laboratorio-proveta',
                name: 'Proveta',
                routeName: ERoutes.EQUIPAMENTO_PROVETA,
                isVisible: this.isVisible_laboratorio_equipamentos
              },
              {
                id: 'equipamento-laboratorio-salimetro',
                name: 'Salímetro',
                routeName: ERoutes.EQUIPAMENTO_SALIMETRO,
                isVisible: this.isVisible_laboratorio_equipamentos
              },
              {
                id: 'equipamento-laboratorio-termometro',
                name: 'Termômetro',
                routeName: ERoutes.EQUIPAMENTO_TERMOMETRO,
                isVisible: this.isVisible_laboratorio_equipamentos
              },
              {
                id: 'equipamento-laboratorio-titrando',
                name: 'Titrando',
                routeName: ERoutes.EQUIPAMENTO_TITRANDO,
                isVisible: this.isVisible_laboratorio_equipamentos
              },
              {
                id: 'equipamento-laboratorio-titrino',
                name: 'Titrino',
                routeName: ERoutes.EQUIPAMENTO_TITRINO,
                isVisible: this.isVisible_laboratorio_equipamentos
              },
              {
                id: 'equipamento-laboratorio-tubo',
                name: 'Tubo',
                routeName: ERoutes.EQUIPAMENTO_TUBO,
                isVisible: this.isVisible_laboratorio_equipamentos
              }
            ]
          },
          {
            id: 'equipamento-superficie',
            moduleName: SidebarModulesName.EQUIPAMENTO_SUPERFICIE,
            iconName: '',
            hasTopics: false,
            hasSubtopics: true,
            isOpen: false,
            isVisible: this.isVisible_superficie_equipamentos,
            subtopics: [
              {
                id: 'equipamento-superficie-fabricantes',
                isVisible: this.isVisible_Fabricantes,
                name: 'Fabricantes',
                routeName: ERoutes.FABRICANTE
              },
              {
                id: 'equipamento-superficie-cabecotes',
                name: 'Cabeçotes',
                routeName: ERoutes.CABECOTE,
                isVisible: this.isVisible_Cabecote
              },
              {
                id: 'equipamento-transformadores',
                name: 'Transformadores',
                routeName: ERoutes.TRANSFORMADOR,
                isVisible: this.isVisible_Transformador
              },
              {
                id: 'equipamento-unidades-bombeio',
                name: 'Unidades de Bombeio',
                routeName: ERoutes.UNIDADE_BOMBEIO,
                isVisible: this.isVisible_UnidadesBombeio
              }
            ]
          }
        ],
        subtopics: [
          {
            id: 'equipamento-tags',
            name: 'Tags',
            routeName: ERoutes.TAG,
            isVisible: this.isVisible_Tags
          },
          {
            id: 'equipamento-pontos-medicao',
            name: 'Pontos de Medição',
            routeName: ERoutes.PONTO_MEDICAO,
            isVisible: this.isVisible_PontosMedicao
          }
        ]
      },
      {
        id: 'laboratorio',
        moduleName: SidebarModulesName.LABORATORIO,
        iconName: 'science',
        hasTopics: false,
        hasSubtopics: true,
        isOpen: false,
        isVisible: this.isVisible_Laboratorio,
        subtopics: [
          {
            id: 'laboratorio-amostras',
            name: 'Amostras',
            routeName: ERoutes.AMOSTRA,
            isVisible: this.isVisible_Amostras
          },
          {
            id: 'laboratorio-analise-bsws',
            name: 'BSWs',
            routeName: ERoutes.BSW,
            isVisible: this.isVisible_BSWs
          },
          {
            id: 'laboratorio-cromatografia',
            name: 'Cromatografias',
            routeName: ERoutes.CROMATOGRAFIA,
            isVisible: this.isVisible_Cromatografias
          },
          {
            id: 'laboratorio-amostras-densidade',
            name: 'Densidades & Grau API',
            routeName: ERoutes.DENSIDADE,
            isVisible: this.isVisible_Densidades
          },
          {
            id: 'laboratorio-fers',
            name: 'FE & RS',
            isVisible: this.isVisible_FERSs,
            routeName: ERoutes.FERS
          },
          {
            id: 'laboratorio-h2s',
            name: 'H2S',
            isVisible: this.isVisible_H2Ss,
            routeName: ERoutes.H2S
          },
          {
            id: 'laboratorio-salinidade',
            name: 'Salinidade',
            isVisible: this.isVisible_Salinidades,
            routeName: ERoutes.SALINIDADE
          }
        ]
      },
      {
        id: 'administracao',
        moduleName: SidebarModulesName.ADMINISTRACAO,
        iconName: 'groups',
        hasTopics: true,
        hasSubtopics: false,
        isOpen: false,
        isVisible: this.isVisible_Administracao,
        topics: [
          {
            id: 'administracao-cadastro',
            moduleName: SidebarModulesName.CADASTRO,
            iconName: '',
            hasTopics: false,
            hasSubtopics: true,
            isOpen: false,
            isVisible: this.isVisible_AdministracaoCadastro,
            subtopics: [
              {
                id: 'administracao-cadastro-cargos',
                name: 'Cargos',
                routeName: ERoutes.CARGO,
                isVisible: this.isVisible_Cargos
              },
              {
                id: 'administracao-cadastro-colaboradores',
                name: 'Colaboradores',
                routeName: ERoutes.COLABORADOR,
                isVisible: this.isVisible_Colaboradores
              },
              {
                id: 'administracao-cadastro-empresas',
                name: 'Empresas',
                routeName: ERoutes.EMPRESA,
                isVisible: this.isVisible_Empresas
              },
              {
                id: 'administracao-cadastro-perfis',
                name: 'Perfis',
                routeName: ERoutes.PERFIL,
                isVisible: this.isVisible_Perfis
              },
              {
                id: 'administracao-cadastro-usuarios',
                name: 'Usuários',
                routeName: ERoutes.USUARIO,
                isVisible: this.isVisible_Usuarios
              }
            ]
          }
        ],
        subtopics: []
      }
    ];
  }

  private sidebarRouting: SidebarModuleRoutingType[];

  getRoutes() {
    this.updatePermission();
    this.sidebarRouting = this.createSideBar();
    return this.sidebarRouting.filter((f) => f.isVisible);
  }

  hasAnyPermission(ePermission: EPermissions[]) {
    return this.permissionService.userHasPermissions(ePermission);
  }

  validateSearch(modules: SidebarModuleRoutingType[], textSearch: string) {
    return modules.filter((module) => {
      if (compareStrings(module.moduleName, textSearch)) {
        return module;
      }
      if (module.hasSubtopics && module.subtopics.filter((subtopic) => compareStrings(subtopic.name, textSearch)).length > 0) {
        module.isOpen = true;
        return module;
      }
      if (module.hasTopics) {
        if (
          module.topics.filter((topic) => {
            if (topic.hasSubtopics) {
              if (topic.subtopics.filter((subtopic) => compareStrings(subtopic.name, textSearch)).length > 0) {
                module.isOpen = true;
                topic.isOpen = true;
                return module;
              }
            }
          }).length > 0
        ) {
          module.isOpen = true;
          return module;
        }
        if (module.topics.filter((topic) => compareStrings(topic.moduleName, textSearch)).length > 0) {
          module.isOpen = true;
          return module;
        }
      }
    });
  }

  private updatePermission() {
    //dashboard
    this.isVisible_Dashboard = true;

    //Gestão da Produção > Cadastro
    this.isVisible_Campos = this.hasAnyPermission([EPermissions.VISUALIZAR_CAMPO]);
    this.isVisible_Estacoes = this.hasAnyPermission([EPermissions.VISUALIZAR_ESTACAO]);
    this.isVisible_Escoamentos = this.hasAnyPermission([EPermissions.VISUALIZAR_ESCOAMENTO]);
    this.isVisible_Pocos = this.hasAnyPermission([EPermissions.VISUALIZAR_POCO]);
    this.isVisible_Clusters = this.hasAnyPermission([EPermissions.VISUALIZAR_CLUSTER]);
    this.isVisible_Nos = this.hasAnyPermission([EPermissions.VISUALIZAR_NO]);
    this.isVisible_Satelites = this.hasAnyPermission([EPermissions.VISUALIZAR_SATELITE]);
    this.isVisible_RamaisEletricos = this.hasAnyPermission([EPermissions.VISUALIZAR_RAMAL_ELETRICO]);
    this.isVisible_TestesInjecao = this.hasAnyPermission([EPermissions.VISUALIZAR_TESTE_INJECAO]);

    this.isVisible_GestaoProducaoCadastro =
      this.isVisible_Estacoes ||
      this.isVisible_Escoamentos ||
      this.isVisible_Campos ||
      this.isVisible_Pocos ||
      this.isVisible_Nos ||
      this.isVisible_Satelites ||
      this.isVisible_RamaisEletricos ||
      this.isVisible_Clusters ||
      this.isVisible_TestesInjecao;

    //Gestão da Produção > Medições > Fiscais
    this.isVisible_MedicoesFiscaisLinhaGas = this.hasAnyPermission([EPermissions.VISUALIZAR_MEDICAO_FISCAL_LINHA]);
    this.isVisible_MedicoesFiscaisLinhaOleo = this.hasAnyPermission([EPermissions.VISUALIZAR_MEDICAO_FISCAL_LINHA]);
    this.isVisible_MedicoesFiscaisTanque = this.hasAnyPermission([EPermissions.VISUALIZAR_MEDICAO_FISCAL_TANQUE]);

    this.isVisible_GestaoProducaoMedicaoFiscal =
      this.isVisible_MedicoesFiscaisLinhaGas || this.isVisible_MedicoesFiscaisLinhaOleo || this.isVisible_MedicoesFiscaisTanque;
    this.isVisible_GestaoProducaoMedicao = this.isVisible_GestaoProducaoMedicaoFiscal;

    //Gestão da Produção
    this.isVisible_Captacoes = this.hasAnyPermission([EPermissions.VISUALIZAR_CAPTACAO]);
    this.isVisible_ConfigSuperficiePoco = this.hasAnyPermission([EPermissions.VISUALIZAR_CONFIG_SUPERFICIE_POCO]);
    this.isVisible_MalhaInjecao = this.hasAnyPermission([EPermissions.VISUALIZAR_MALHA_INJECAO]);
    this.isVisible_TestesInjecao = this.hasAnyPermission([EPermissions.VISUALIZAR_TESTE_INJECAO]);
    this.isVisible_TestesPoco = this.hasAnyPermission([EPermissions.VISUALIZAR_TESTE_POCO]);
    this.isVisible_Medicao_Operacional_Queima = this.hasAnyPermission([EPermissions.VISUALIZAR_MEDICAO_OPERACIONAL_QUEIMA]);

    this.isVisible_GestaoProducao =
      this.isVisible_GestaoProducaoCadastro ||
      this.isVisible_GestaoProducaoMedicao ||
      this.isVisible_TestesInjecao ||
      this.isVisible_TestesPoco ||
      this.isVisible_ConfigSuperficiePoco ||
      this.isVisible_Captacoes ||
      this.isVisible_Medicao_Operacional_Queima ||
      this.isVisible_MalhaInjecao;

    // Gestão de Ocorrência > Cadastro
    this.isVisible_GrupoMotivoOcorrencia = this.hasAnyPermission([EPermissions.VISUALIZAR_GRUPO_MOTIVO_OCORRENCIA]);
    this.isVisible_GrupoProvidencia = this.hasAnyPermission([EPermissions.VISUALIZAR_GRUPO_PROVIDENCIA_OCORRENCIA]);
    this.isVisible_TipoResponsavel = this.hasAnyPermission([EPermissions.VISUALIZAR_TIPO_RESPONSAVEL_OCORRENCIA]);
    this.isVisible_MotivoOcorrencia = this.hasAnyPermission([EPermissions.VISUALIZAR_MOTIVO_OCORRENCIA]);
    this.isVisible_ResponsavelOcorrencia = this.hasAnyPermission([EPermissions.VISUALIZAR_RESPONSAVEL_OCORRENCIA]);
    this.isVisible_ProvidenciaOcorrencia = this.hasAnyPermission([EPermissions.VISUALIZAR_PROVIDENCIA_OCORRENCIA]);
    this.isVisible_agrupamento_motivo_providencia_responsavel = this.hasAnyPermission([
      EPermissions.VISUALIZAR_AGRUPAMENTO_MOTIVO_PROVIDENCIA_RESPONSAVEL
    ]);

    this.isVisible_GestaoOcorrenciaCadastro =
      this.isVisible_GrupoMotivoOcorrencia ||
      this.isVisible_GrupoProvidencia ||
      this.isVisible_TipoResponsavel ||
      this.isVisible_ProvidenciaOcorrencia ||
      this.isVisible_MotivoOcorrencia ||
      this.isVisible_ResponsavelOcorrencia ||
      this.isVisible_agrupamento_motivo_providencia_responsavel;

    this.isVisible_Ocorrencia = this.hasAnyPermission([EPermissions.VISUALIZAR_OCORRENCIA]);
    this.isVisible_Ocorrencia_Injetores = this.hasAnyPermission([EPermissions.VISUALIZAR_OCORRENCIA]);

    // Gestão de Ocorrência
    this.isVisible_GestaoOcorrencia =
      this.isVisible_GestaoOcorrenciaCadastro || this.isVisible_Ocorrencia || this.isVisible_Ocorrencia_Injetores;

    //Fechamento da Produção
    this.isVisible_BMP = this.hasAnyPermission([EPermissions.GERAR_BMP]);
    this.isVisible_ProducaoDiaria = this.hasAnyPermission([EPermissions.PRODUCAO_DIARIA_DE_PRODUCAO]);

    this.isVisible_FechamentoProducao = this.isVisible_ProducaoDiaria || this.isVisible_BMP;

    // Relatórios
    this.isVisible_RelatorioTransferenciaFiscaisTanque = this.hasAnyPermission([EPermissions.GERAR_RELATORIO_TRANSFERENCIA_FISCAL_TANQUE]);
    this.isVisible_Relatorios_Analitico_Injecoes_Diarias = this.hasAnyPermission([EPermissions.INJECAO_DIARIA_DE_PRODUCAO]);
    this.isVisible_RelatorioMedicaoFiscal = this.hasAnyPermission([EPermissions.GERAR_RELATORIO_MEDICAO_FISCAL]);
    this.isVisible_RelatorioMedicaoOperacionalQueima = this.hasAnyPermission([EPermissions.GERAR_RELATORIO_OPERACIONAL_QUEIMA]);
    this.isVisible_Relatorios_Sintetico_Injecoes_Diarias = this.hasAnyPermission([EPermissions.INJECAO_DIARIA_DE_PRODUCAO]);
    this.isVisible_Relatorios_Injecoes_Diarias =
      this.isVisible_Relatorios_Analitico_Injecoes_Diarias || this.isVisible_Relatorios_Sintetico_Injecoes_Diarias;

    this.isVisible_RelatorioMTBF = this.hasAnyPermission([EPermissions.GERAR_RELATORIO_MTBF]);
    this.isVisible_RelatorioPerda = this.hasAnyPermission([EPermissions.GERAR_RELATORIO_PERDA]);
    this.isVisible_RelatorioProducoesDiarias = this.hasAnyPermission([EPermissions.PRODUCAO_DIARIA_DE_PRODUCAO]);

    this.isVisible_Relatorios =
      this.isVisible_Relatorios_Analitico_Injecoes_Diarias ||
      this.isVisible_RelatorioMedicaoFiscal ||
      this.isVisible_RelatorioTransferenciaFiscaisTanque ||
      this.isVisible_RelatorioMTBF ||
      this.isVisible_RelatorioPerda ||
      this.isVisible_RelatorioProducoesDiarias ||
      this.isVisible_RelatorioMedicaoOperacionalQueima;

    // Gestão de Prazos
    this.isVisible_GestaoPrazoTestePocoTeste = this.hasAnyPermission([EPermissions.GERAR_RELATORIO_GESTAO_PRAZO_TESTE_POCO]);
    this.isVisible_GestaoPrazoInstrumento = this.hasAnyPermission([EPermissions.GERAR_RELATORIO_GESTAO_PRAZO_INSTRUMENTO]);

    this.isVisible_GestaoPrazos = this.isVisible_GestaoPrazoTestePocoTeste || this.isVisible_GestaoPrazoInstrumento;

    //Reservatório > Cadastro
    this.isVisible_Reservatorios = this.hasAnyPermission([EPermissions.VISUALIZAR_RESERVATORIO]);
    this.isVisible_RGO = this.hasAnyPermission([EPermissions.VISUALIZAR_RGO]);
    this.isVisible_ZonasProdutoras = this.hasAnyPermission([EPermissions.VISUALIZAR_ZONA_PRODUTORA]);

    this.isVisible_ReservatorioCadastro = this.isVisible_ZonasProdutoras || this.isVisible_Reservatorios || this.isVisible_RGO;

    //Reservatório
    this.isVisible_Potencial = this.hasAnyPermission([EPermissions.VISUALIZAR_POTENCIAL]);
    this.isVisible_AssociarZonas = this.hasAnyPermission([EPermissions.VISUALIZAR_ZONA]);

    this.isVisible_Reservatorio = this.isVisible_ReservatorioCadastro || this.isVisible_Potencial || this.isVisible_AssociarZonas;

    //Equipamento > Laboratório
    this.isVisible_laboratorio_equipamentos = this.hasAnyPermission([EPermissions.VISUALIZAR_EQUIPAMENTO]);

    //Equipamento > Instrumentos
    this.isVisible_instrumentos = this.hasAnyPermission([EPermissions.VISUALIZAR_INSTRUMENTO]);

    //Equipamento > Superfície
    this.isVisible_Cabecote = this.hasAnyPermission([EPermissions.VISUALIZAR_CABECOTE]);
    this.isVisible_Transformador = this.hasAnyPermission([EPermissions.VISUALIZAR_TRANSFORMADOR]);
    this.isVisible_UnidadesBombeio = this.hasAnyPermission([EPermissions.VISUALIZAR_UNIDADE_BOMBEIO]);
    this.isVisible_Fabricantes = this.hasAnyPermission([EPermissions.VISUALIZAR_FABRICANTE]);

    this.isVisible_superficie_equipamentos =
      this.isVisible_Cabecote || this.isVisible_Transformador || this.isVisible_UnidadesBombeio || this.isVisible_Fabricantes;

    //Equipamento
    this.isVisible_Tags = this.hasAnyPermission([EPermissions.VISUALIZAR_TAG]);
    this.isVisible_PontosMedicao = this.hasAnyPermission([EPermissions.VISUALIZAR_PONTO_MEDICAO]);
    this.isVisible_Lotes = this.hasAnyPermission([EPermissions.VISUALIZAR_LOTE]);
    this.isVisible_Lacres = this.hasAnyPermission([EPermissions.VISUALIZAR_LACRE]);
    this.isVisible_Fornecedores = this.hasAnyPermission([EPermissions.VISUALIZAR_FORNECEDOR]);
    this.isVisible_GestaoLacres = this.isVisible_Lotes || this.isVisible_Lacres || this.isVisible_Fornecedores;

    this.isVisible_Equipamento =
      this.isVisible_laboratorio_equipamentos ||
      this.isVisible_instrumentos ||
      this.isVisible_superficie_equipamentos ||
      this.isVisible_Tags ||
      this.isVisible_PontosMedicao ||
      this.isVisible_GestaoLacres;

    //Administração > Cadastro
    this.isVisible_Empresas = this.hasAnyPermission([EPermissions.VISUALIZAR_EMPRESA]);
    this.isVisible_Colaboradores = this.hasAnyPermission([EPermissions.VISUALIZAR_COLABORADOR]);
    this.isVisible_Usuarios = this.hasAnyPermission([EPermissions.VISUALIZAR_USUARIO]);
    this.isVisible_Cargos = this.hasAnyPermission([EPermissions.VISUALIZAR_CARGO]);
    this.isVisible_Perfis = this.hasAnyPermission([EPermissions.VISUALIZAR_PERFIL]);

    this.isVisible_AdministracaoCadastro =
      this.isVisible_Empresas || this.isVisible_Colaboradores || this.isVisible_Usuarios || this.isVisible_Cargos || this.isVisible_Perfis;

    this.isVisible_Administracao = this.isVisible_AdministracaoCadastro;

    //Laboratório
    this.isVisible_Amostras = this.hasAnyPermission([EPermissions.VISUALIZAR_AMOSTRA]);
    this.isVisible_BSWs = this.hasAnyPermission([EPermissions.VISUALIZAR_BSW]);
    this.isVisible_Cromatografias = this.hasAnyPermission([EPermissions.VISUALIZAR_CROMATOGRAFIA]);
    this.isVisible_Densidades = this.hasAnyPermission([EPermissions.VISUALIZAR_DENSIDADE]);
    this.isVisible_FERSs = this.hasAnyPermission([EPermissions.VISUALIZAR_FERS]);
    this.isVisible_H2Ss = this.hasAnyPermission([EPermissions.VISUALIZAR_H2S]);
    this.isVisible_Salinidades = this.hasAnyPermission([EPermissions.VISUALIZAR_SALINIDADE]);

    this.isVisible_Laboratorio =
      this.isVisible_Amostras ||
      this.isVisible_BSWs ||
      this.isVisible_Cromatografias ||
      this.isVisible_Densidades ||
      this.isVisible_FERSs ||
      this.isVisible_H2Ss ||
      this.isVisible_Salinidades;
  }
}

<mat-spinner *ngIf="isLoading" class="align-center h-12"></mat-spinner>

<highcharts-chart
  [style.visibility]="isLoading ? 'hidden' : ''"
  [Highcharts]="_highcharts"
  [options]="chartOptions"
  [runOutsideAngular]="true"
  [(update)]="updateFlag"
  (chartInstance)="onInstance($event)"
  style="width: 100%; height: 600px; display: block"
></highcharts-chart>

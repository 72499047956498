<fieldset [disabled]="isReadonly" class="relative my-4 w-full disabled:bg-transparent">
  <div class="input-wrapper disabled:bg-transparent" [ngClass]="isReadonly ? 'border-gray-400' : 'border-gray-400'">
    <input
      [type]="revealed ? 'text' : type"
      [id]="id"
      [name]="id"
      [(ngModel)]="value"
      [placeholder]="label"
      [step]="step"
      [maxlength]="maxLength"
      class="input-base peer bg-transparent text-gray-900"
      [mask]="mask"
      [dropSpecialCharacters]="false"
      (ngModelChange)="_handleChange()"
      autocomplete="off"
      [max]="maxPattern"
      (blur)="_onBlur()"
      (keypress)="_onKeyPress()"
    />
    <label
      [attr.for]="id"
      class="float-label-input"
      [ngClass]="isReadonly ? 'bg-gray-100 text-gray-300' : labelBgColor ? 'bg-' + labelBgColor : 'bg-gray-100 text-dark-blue-puple'"
      >{{ label }}</label
    >
    <button
      type="button"
      (click)="revealPassword()"
      [title]="revealed ? 'Esconder senha' : 'Mostrar senha'"
      *ngIf="type === 'password'"
      class="flex items-center"
    >
      <span class="material-icons button-icon text-2xl text-dark-blue-puple">{{ revealed ? 'visibility_off' : 'visibility' }}</span>
    </button>
  </div>
  <div>
    <span class="span-required" *ngIf="control.hasError('required') && submitted">* Campo obrigatório</span>
    <span class="span-required" *ngIf="control.hasError('invalidDate') && submitted">Data inválida</span>
    <span class="span-required" *ngIf="control.hasError('email') && submitted">Precisa ser informado um email válido</span>
    <span class="span-required" *ngIf="(control.hasError('maxlength') && submitted) || (control.hasError('minlength') && submitted)"
      >Tamanho Inválido</span
    >
  </div>
</fieldset>

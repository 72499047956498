import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DataSource } from '@core/types/data-source.type';
import { InfoBlockDetailComponentConfig } from './info-block-detail.config';

@Component({
  selector: 'info-block-detail',
  templateUrl: 'info-block-detail.component.html'
})
export class InfoBlockDetailComponent implements OnInit, OnChanges {
  @Input() title?: string;
  @Input() dataSources?: DataSource[][];
  @Input() classTitle?: string;
  @Input() class?: string;

  @Input() configuration?: InfoBlockDetailComponentConfig = {
    borderConfig: {
      isVisible: true,
      class: 'mb-4 border-b'
    },
    titleConfig: {
      isVisible: true,
      class: 'text-lg'
    },
    isVisible: true,
    class: 'mb-8 w-9/12'
  };

  maxColumns = 0;
  maxCaracteres = 0;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe([Breakpoints.XLarge]).subscribe((result) => {
      if (result.matches) {
        this.maxCaracteres = 25;
      } else {
        this.maxCaracteres = 15;
      }
    });
  }

  ngOnInit(): void {
    this.maxColumns = this.maxColumnsDataSource;
    if (this.class) {
      this.configuration.class = this.class;
    }
    if (this.classTitle) {
      this.configuration.titleConfig.class = this.classTitle;
    }
  }

  get applyDefaultClass() {
    return this.class ? this.class : 'mb-8 w-9/12';
  }

  get maxColumnsDataSource() {
    let max = 0;
    this.dataSources?.forEach((row) => {
      max = row.length > max ? row.length : max;
    });
    return max * 2;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.maxColumns = this.maxColumnsDataSource;
    this.class = this.applyDefaultClass;
  }
}

export enum ERoutesApi {
  AUTH = 'auth-web',
  TOKEN_REFRESH = 'token_refresh',
  RECUPERAR_SENHA = 'recuperar_senha',
  RECUPERAR_SENHA_CODIGO = 'recuperar_senha_confirmar_codigo',
  ALTERAR_SENHA = 'alterar_senha',
  ALTERAR_ESTADO = 'alterar_estado',
  ESTACOES = 'estacoes',
  ESTACOES_SIGLA = 'estacoes_sigla',
  TIPOS_ESTACAO = 'tipos-estacao',
  TIPOS_ESCOAMENTO = 'tipos-escoamento',
  EMPRESAS = 'empresas',
  PERFIS = 'perfis',
  PERMISSOES = 'permissoes',
  AMOSTRAS = 'amostras',
  CARGOS = 'cargos',
  TAGS = 'tags',
  TIPOS_TAGS = 'tipos',
  CROMATOGRAFIAS = 'cromatografias',
  MANOMETROS = 'manometros',
  CAMPOS = 'campos',
  CLUSTERS = 'clusters',
  CAMPOS_SIGLA = 'campos_sigla',
  CICLOS = 'ciclos-medicoes-fiscais-tanque',
  EXPORTAR_MEDICOES_TRANSFERENCIA = 'exportar_medicoes_transferencia',
  INJECOES = 'injecoes',
  TIPOS_INJECAO = 'tipos_injecao',
  ORIGENS_INJECAO = 'origens',
  UNIDADE_ENGENHARIA_PADRAO = 'unidade_engenharia_padrao',
  UGVS = 'ugvs',
  ZONAS_PRODUTORA = 'zonas-produtora',
  ZONAS = 'zonas',
  PONTOS_MEDICAO = 'pontos-medicao',
  TIPOS_MEDICAO = 'tipos_medicao',
  ULTIMA_MEDICAO = 'ultima_medicao',
  TIPOS_PONTO_MEDICAO = 'tipos_ponto_medicao',
  TIPOS_MEDIDORES_PADRAO = 'tipos_medidores_padrao',
  FLUIDOS = 'fluidos',
  MEDICOES_FISCAIS_LINHA = 'medicoes-fiscais-linha',
  MEDICOES_FISCAIS_LINHA_IMPORTADOS = 'medicoes-fiscais-linha-importados',
  POCOS = 'pocos',
  POCOS_PRODUTORES = 'pocos_produtores',
  POCOS_INJETORES = 'pocos_injetores',
  TIPOS_POCO = 'tipos_poco',
  FUNCONES_POCO = 'funcoes_poco',
  CONDICOES_POCO = 'condicoes_poco',
  STATUS_POCO = 'status_poco',
  ESTADOS_POCO = 'estados_poco',
  BSWS = 'bsws',
  TIPOS_BSWS = 'bsw_tipos',
  DENSIDADES = 'densidades',
  FATORES_ENCOLHIMENTOS = 'fatores-encholhimento',
  RAZOES_SOLUBILIDADE = 'razoes-solubilidade',
  FATORES_RAZOES = 'fatores-razoes',
  RGOS = 'rgos',
  METODOS_ELEVACAO = 'metodos_elevacao',
  CRITICIDADES_AREIA = 'criticidades_areia',
  MEDICOES_FISCAIS_TANQUE = 'medicoes-fiscais-tanque',
  COLABORADORES = 'colaboradores',
  TECNICOS = 'tecnicos',
  USUARIOS = 'usuarios',
  TESTES_POCO = 'testes-poco',
  TESTES_POCO_VALIDACAO = 'testes-poco-validacao',
  RESERVATORIOS = 'reservatorios',
  CAPTACAO = 'captacoes',
  MEDICOES_OPERACIONAIS_QUEIMA = 'medicoes-operacional-queima',
  CLASSIFICACOES = 'classificacoes',
  FUNCOES = 'funcoes',
  CARACTERISTICAS = 'caracteristicas',
  POCOS_ZONA = 'pocos-zona',
  MEDICOES_AGUA = 'medicoes-agua',
  MUNICIPIOS = 'municipios',
  EXPORT_CSV = 'exportar_csv',
  EXPORT_CSV_RESUMO = 'exportar_csv_resumo',
  AMBIENTE = 'ambiente',
  PRODUCAO_FECHAMENTO = 'producao-fechamento',
  RELATORIO_DIARIO = 'relatorio_diario',
  RELATORIOS_MEDICAO_FISCAL = 'relatorios-medicao-fiscal',
  BMPS = 'bmps',
  GERAR_BMP = 'gerar_bmp',
  CONSOLIDAR_BMP = 'consolidar_bmp',
  PROCESSAR_PRODUCAO_DIARIA_MENSAL = 'processar_producao_mensal',
  PROCESSAR_PRODUCAO_DIARIA_PERIODO = 'processar_producao_periodo',
  EQUIPAMENTOS = 'equipamentos',
  INSTRUMENTOS = 'instrumentos',
  CALIBRACAO_INSTRUMENTO = 'calibracao-instrumentos',
  FABRICANTES = 'fabricantes',
  UNIDADES_BOMBEIO = 'unidades-bombeio',
  TRANSFORMADORES = 'transformadores',
  CABECOTES = 'cabecotes',
  EQUIPAMENTOS_TAGS_DISPONIVEIS = 'tags_disponiveis',
  TAGS_DISPONIVEIS = 'tags_disponiveis',
  H2S = 'h2s',
  SALINIDADES = 'salinidades',
  METODOS = 'metodos',
  MEDIA = 'media',
  GAS_VENTILADO = 'boletins-gas-ventilado',
  ACOMPANHAMENTO_POCOS = 'acompanhamento-pocos',
  OTIMIZACOES = 'otimizacoes',
  OTIMIZACOES_RECOMENDACAO = 'otimizacoes_recomendacao',
  GERAR_BRA = 'gerar_bra',
  CONSOLIDAR_BRA = 'consolidar_bra',
  GERAR_RELATORIO = 'gerar_relatorio',
  NO = 'nos',
  SATELITE = 'satelites',
  RAMAL_ELETRICO = 'ramais-eletricos',
  MOTIVO_OCORRENCIA = 'motivos-ocorrencia',
  TIPO_RESPONSAVEL_OCORRENCIA = 'tipos-responsavel-ocorrencia',
  GRUPO_MOTIVO_OCORRENCIA = 'grupos-motivo-ocorrencia',
  GRUPO_PROVIDENCIA_OCORRENCIA = 'grupos-providencia-ocorrencia',
  PROVIDENCIA_OCORRENCIA = 'providencias-ocorrencia',
  RESPONSAVEL_OCORRENCIA = 'responsaveis-ocorrencia',
  AGRUPAMENTO_MOTIVO_PROVIDENCIA_RESPONSAVEL_OCORRENCIA = 'agrupamentos-motivos-providencias-responsaveis',
  MOTIVO_PROVIDENCIA_RESPONSAVEL = 'motivo_providencia_responsavel',
  OCORRENCIAS = 'ocorrencias',
  TESTE_INJECAO = 'testes-injecao',
  ITENS_PARA_FILTRO = 'itens-para-filtro',
  CONFIGURACAO_SUPERFICIE_POCO = 'configuracoes-superficie',
  LOCACAO = 'locacoes',
  TIPOS_PROJETOS = 'tipos-projeto',
  LOTES = 'lotes',
  LACRES = 'lacres',
  FALHAS = 'falhas',
  NOS = 'nos',
  RELATORIOS_MTBF = 'relatorios-mtbf',
  GESTAO_PRAZO_TESTE_POCO = 'gestao-prazos-testes-poco',
  GESTAO_PRAZO_TESTE_POCO_ENVIO = 'gestao-prazos-envio-testes-poco',
  GESTAO_PRAZO_CALIBRACAO_INSTRUMENTO = 'gestao-prazos-calibracao',
  GESTAO_PRAZO_GAS_VENTILADO = 'gestao-prazos-gas-ventilado',
  GESTAO_PRAZO_MEDICAO_FISCAL_TANQUE = 'gestao-prazos-medicao-fiscal-tanque',
  GESTAO_PRAZO_MEDICAO_FISCAL_LINHA_GAS = 'gestao-prazos-medicao-fiscal-linha-gas',
  GESTAO_PRAZO_MEDICAO_FISCAL_LINHA_OLEO = 'gestao-prazos-medicao-fiscal-linha-oleo',
  GESTAO_PRAZO_BSW = 'gestao-prazos-medicao-fiscal-linha-bsw',
  GESTAO_PRAZO_FERS = 'gestao-prazos-medicao-fiscal-linha-fator',
  GESTAO_CARTAS = 'gestao-cartas',
  RELATORIOS_PERDA = 'relatorios-perda-por-poco',
  RELATORIOS_PERDA_CAMPO = 'relatorios-perda-por-campo',
  RELATORIOS_PERDA_NO = 'relatorios-perda-por-no',
  RELATORIO_ANALITICO_INJECAO_DIARIA = 'relatorios-injecao/injecao_diaria_por_poco_analitico',
  RELATORIO_SINTETICO_INJECAO_DIARIA = 'relatorios-injecao/injecao_diaria_por_poco_sintetico',
  POTENCIAS = 'potenciais',
  MALHAS_INJECOES = 'malhas-injecoes',
  CONFIGURACOES = 'configuracoes',
  DASHBOARD = 'dashboard',
  ESCOAMENTOS = 'escoamentos',
  FORNECEDORES = 'fornecedores'
}

import { Injectable } from '@angular/core';
import { ERoutesApi } from '@core/enums';
import { GenericCrudService } from '@core/services';
import { CargoService } from '@features/cargos/services';
import { ColaboradorService } from '@features/colaboradores/services';
import { EmpresaService } from '@features/empresas/services';
import { PerfilService } from '@features/perfis/services';
import { forkJoin, map, Observable, of } from 'rxjs';
import { AlterarSenhaInput, CodeInput, IUsuarioService, RecuperarSenhaInput, UsuarioFormFetch } from '../adapters';
import { Usuario, UsuarioAPI } from '../model';

@Injectable({ providedIn: 'root' })
export class UsuarioService extends GenericCrudService<UsuarioAPI, Usuario> implements IUsuarioService {
  constructor(
    private readonly perfilService: PerfilService,
    private readonly empresasService: EmpresaService,
    private readonly cargosService: CargoService,
    private readonly colaboradorService: ColaboradorService
  ) {
    super(ERoutesApi.USUARIOS);
  }

  handleFetchFormInformation(id?: string | number): Observable<UsuarioFormFetch> {
    return forkJoin([
      this.empresasService.handleFetchLikeSelecterOptions(),
      this.cargosService.handleFetchLikeSelecterOptions(),
      this.perfilService.handleFetchLikeSelecterOptions(),
      this.colaboradorService.handleFetchTipoColaboradorLikeSelecterOptions(),
      id ? this.handleFetchById(id) : of(undefined)
    ]).pipe(
      map<any[], UsuarioFormFetch>((values) => ({
        empresasOptions: values[0],
        cargosOptions: values[1],
        perfisOptions: values[2],
        tiposColaboradorOptions: values[3],
        usuario: values[4]
      }))
    );
  }

  handleRecuperarSenha(input: RecuperarSenhaInput): Observable<string> {
    return this.httpService.post(`${ERoutesApi.USUARIOS}/${ERoutesApi.RECUPERAR_SENHA}`, input);
  }

  handleCode(input: CodeInput): Observable<string> {
    return this.httpService.post(`${ERoutesApi.USUARIOS}/${ERoutesApi.RECUPERAR_SENHA_CODIGO}`, input);
  }

  handleAlterarSenha(id: string | number, input: AlterarSenhaInput): Observable<string> {
    return this.httpService.post(`${ERoutesApi.USUARIOS}/${id}/${ERoutesApi.ALTERAR_SENHA}`, input);
  }
}

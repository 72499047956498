<div class="relative">
  <!--<div *ngIf="environment" class="fixed top-0 left-0 right-0 z-[9999] flex w-full items-center justify-center bg-red-600">
    <span class="text-xl font-semibold text-white"> {{environment }}</span>
  </div>-->
  <div *ngIf="isLoggedUser" class="container mx-auto h-screen max-w-none">
    <app-sidebar></app-sidebar>
    <div class="flex h-full flex-row items-start justify-end transition-all duration-150">
      <div
        class="h-full pt-1 transition-all duration-300 bg-gray-100"
        [ngClass]="isSidebarOpen ? 'xs:w-10/12 sm:w-8/12 md:w-8/12 w-9/12 xl:w-10/12 2xl:w-10/12' : 'xl:w-[95%] 2xl:w-[95%] xs:w-[95%] md:w-[92%] sm:w-[92%] w-[94%]'"
      >
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <div *ngIf="!isLoggedUser">
    <router-outlet></router-outlet>
  </div>
</div>

<section [ngClass]="class">
  <h3 [ngClass]="classTitle ? classTitle : 'text-lg'" *ngIf="title">{{ title }}</h3>
  <hr class="mb-4 border-b" *ngIf="title" />
  <ng-container *ngFor="let rows of dataSources">
    <div class="grid grid-cols-{{ maxColumns }} text-basic my-4">
      <ng-container class="flex flex-wrap items-center" *ngFor="let col of rows">
        <h3 class="flex items-center" *ngIf="col.isVisible">{{ col.label }}</h3>
        <h3 class="flex items-center font-medium" *ngIf="col.isVisible && col.type === 'number'">
          {{ col.value ? (col.value | number : (col.digitsInfo ?? '') : 'pt') : '-' }}
          <h4 *ngIf="col.subvalue" class="ml-2 text-xs font-normal">{{col.subvalue}}</h4>
        </h3>
        <h3 class="flex items-center font-medium" *ngIf="col.isVisible && col.type === 'date'">
          {{ col.value ? (col.value | date : 'dd/MM/yyy') : '-' }}
        </h3>
        <h3 class="flex items-center font-medium" *ngIf="col.isVisible && col.type === 'date-time'">
          {{ col.value ? (col.value | date : 'dd/MM/yyy HH:mm') : '-' }}
        </h3>
        <h3 class="flex items-center font-medium" *ngIf="col.isVisible && col.type === 'date-time-milliseconds'">
          {{ col.value ? (col.value | date : 'dd/MM/yyyy - HH:mm:ss.SSS') : '-' }}
        </h3>
        <h3 class="flex items-center font-medium" *ngIf="col.isVisible && col.type === 'phone'">
          {{ col.value ? (col.value | phone) : '-' }}
        </h3>
        <h3
          [title]="col.value?.length > maxCaracteres ? col.value : ''"
          class="flex items-center font-medium col-span-{{col.colSpan ? col.colSpan : 1 }}"
          *ngIf="col.isVisible && col.type === 'text'"
        >
          {{ col.value ? col.value?.length > maxCaracteres ? col.value.slice(0, maxCaracteres) + '...' : col.value :'-'}}
        </h3>
        <h3 class="flex items-center font-medium col-span-{{ maxColumns - 1 }}" *ngIf="col.isVisible && col.type === 'text-area'">
          {{ col.value || '-' }}
        </h3>
      </ng-container>
    </div>
  </ng-container>
  <ng-content></ng-content>
</section>

import { Component, DoCheck, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ERoutes } from '@core/enums';
import { User } from '@core/models';
import { AuthService, SidebarService } from '@core/services';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements DoCheck, OnInit {
  isLoggedUser = false;
  user: User = null;
  isOpenOptions = false;
  isSidebarOpen: boolean;

  @Output() openOptions = new EventEmitter();

  constructor(
    private readonly authService: AuthService,
    private readonly sidebarService: SidebarService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.isOpenOptions = false;
    this.isSidebarOpen = this.sidebarService.isSidebarOpen.value;
  }

  ngDoCheck(): void {
    this.isLoggedUser = this.authService.isLoggedUser();
    this.user = this.authService.loggedUser();
    this.isSidebarOpen = this.sidebarService.isSidebarOpen.value;
    this.openOptions.emit(this.isOpenOptions);
  }

  openSidebar() {
    this.sidebarService.isSidebarOpen.next(true);
  }

  logout(): void {
    this.sidebarService.isSidebarOpen.next(false);
    this.isOpenOptions = false;
    this.authService.signOut();
  }

  handleOpenOptions() {
    this.isOpenOptions = !this.isOpenOptions;
  }

  clickedOutside(): void {
    if (this.isOpenOptions) {
      this.isOpenOptions = false;
    }
  }

  handleNavigateToUserProfile() {
    const currentLoggedUser = this.authService.loggedUser();
    this.router.navigate([`${ERoutes.USUARIO}/${ERoutes.PERFIL}`, currentLoggedUser.id]);
  }
}

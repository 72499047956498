<nav
  class="absolute w-[94%] z-[9998] flex flex-col items-center justify-center transition-all duration-300"
  *ngIf="isLoggedUser"
>
  <div class="flex w-full h-full flex-row items-center justify-end">
    <button
      class="absolute px-4 py-3 pl-6 w-full rounded text-dark-blue-puple hover:bg-gray-100"
      (click)="handleOpenOptions()"
      clickOutside
      (clickOutside)="clickedOutside()"
    >
      <div class="flex items-center gap-2 text-dark-blue-puple">
        <span class="material-icons">person</span>
        <span class="font-medium text-dark-blue-puple">{{ user.nome ? user.nome : 'Usuário' }}</span>
        <button title="Expandir" type="button" class="ml-auto flex items-center justify-center rounded-full p-1 text-dark-blue-puple">
          <span class="material-icons transition-all duration-300" [ngClass]="isOpenOptions ? 'rotate-180' : 'rotate-0'">expand_more</span>
        </button>
      </div>
      <ul class="absolute left-6 w-[80%] rounded-b text-dark-blue-puple" [ngClass]="isOpenOptions ? 'block' : 'hidden'">
        <li
          (click)="handleNavigateToUserProfile()"
          title="Perfil"
          class="flex cursor-pointer items-center justify-start gap-2 mt-4 px-4 py-3 pl-6 transition-all duration-300
            font-sans text-base font-base text-dark-blue-puple hover:bg-gray-100"
        >
          Editar
        </li>
        <li
          routerLink="alterar-senha"
          class="flex cursor-pointer items-center justify-start gap-2 mt-2 px-4 py-3 pl-6 transition-all duration-300
            font-sans text-base font-base text-dark-blue-puple hover:bg-gray-100"
        >
          Alterar senha
        </li>
        <li
          (click)="logout()"
          title="Sair"
          class="flex cursor-pointer items-center justify-start gap-2 mt-2 px-4 py-3 pl-6 transition-all duration-300
            font-sans text-red-600 font-base text-dark-blue-puple hover:bg-gray-100"
        >
          Sair
        </li>
      </ul>
    </button>
  </div>

</nav>

<aside
  class="fixed left-0 top-0 mt-[0.75rem] z-[9999] h-screen min-h-screen bg-white pb-8 transition-all duration-300"
  [ngClass]="isSidebarOpen ? 'w-3/12 xs:w-10/12 sm:w-8/12 md:w-4/12 xl:w-2/12 2xl:w-2/12' : 'xl:w-[5%] 2xl:w-[5%] xs:w-[5%] md:w-[8%] sm:w-[8%] w-[6%]'"
  *ngIf="isLoggedUser"
>
  <div class="flex h-full w-full flex-col items-center justify-center p-3">
    <div class="flex w-full flex-col items-center justify-center">
      <app-logo [isToggle]="isSidebarOpen" routerLink="/home" class="cursor-pointer"></app-logo>

      <div class="mt-[1.06rem] ml-14 flex w-full flex-row items-center gap-4" [ngClass]="isSidebarOpen ? ' justify-between' : 'justify-center'">

        <form class="w-11/12" [ngClass]="isSidebarOpen ? 'block' : 'hidden'">
          <div class="input-wrapper flex items-center h-8 border-dark-blue-puple">
            <input
              placeholder="Pesquisar"
              type="text"
              class="input-base bg-transparent text-dark-blue-puple placeholder-dark-blue-puple"
              [(ngModel)]="textSearch"
              id="pesquisar-menu"
              name="pesquisar-menu"
              autocomplete="off"
            />
          </div>
        </form>

        <a *ngIf="!isSidebarOpen" class="w-full flex align-center justify-center">
          <hr *ngIf="!isSidebarOpen" class="mr-1 xs:mr-2 xl:mr-2 2xl:mr-2 w-1 h-1 border-2 border-dark-blue-puple bg-dark-blue-puple rounded-full" />
        </a>

        <button
          [title]="isSidebarOpen ? 'Fechar' : 'Expandir'"
          type="button"
          (click)="handleSidebar()"
          class="flex mr-1 sm:mr-1 md:mr-1 xs:mr-0 xl:mr-0 2xl:mr-0 h-8 w-8 cursor-pointer flex-row items-center justify-center rounded-full bg-dark-blue-puple text-white hover:opacity-90"
          [ngClass]="isSidebarOpen ? 'p-2' : 'p-2'"
        >
          <span
            class="material-icons flex w-5/12 items-center justify-center text-xl"
            [ngClass]="isSidebarOpen ? 'scale-x-100' : '-scale-x-100'"
            >chevron_left</span
          >
        </button>
      </div>
    </div>

    <div class="h-[99%] w-full mt-4 overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300" *ngIf="isSidebarOpen">
      <mat-accordion *ngFor="let option of filteredRoutes">
        <mat-panel-description class="w-full">
          <mat-panel-title class="w-full">
            <a
              [id]="option.id"
              *ngIf="!option.hasSubtopics && !option.hasTopics && option.routeName"
              [routerLink]="option.routeName"
              (click)="handleClickModule(null)"
              class="flex w-full grow-0 cursor-pointer items-center gap-x-[1.3rem] rounded px-6 py-3 font-sans text-base font-medium text-dark-blue-puple hover:bg-gray-200"
            >
              <span class="material-icons">{{ option.iconName }}</span>
              {{ option.moduleName }}
            </a>

            <a
              [id]="option.id"
              *ngIf="!option.hasSubtopics && !option.hasTopics && !option.routeName"
              class="flex w-full cursor-default items-center justify-between rounded px-6 py-3 font-sans text-base font-medium text-dark-blue-puple hover:bg-gray-200"
            >
              <div class="flex items-center">
                <span class="material-icons">{{ option.iconName }}</span> {{ option.moduleName }}
              </div>
              <span class="text-xs font-light italic text-gray-400">em breve</span>
            </a>
          </mat-panel-title>
        </mat-panel-description>

        <mat-expansion-panel
          class="my-0 bg-transparent py-0 text-dark-blue-puple shadow-none"
          [expanded]="textSearch ? option.isOpen : option.moduleName === selectedModule"
          [id]="option.id"
          *ngIf="option.isVisible"
        >
          <mat-expansion-panel-header (click)="handleClickModule(option.moduleName)" *ngIf="option.hasSubtopics || option.hasTopics">
            <mat-panel-title class="grow-0 text-dark-blue-puple">
              <span class="material-icons">{{ option.iconName }}</span>
            </mat-panel-title>

            <mat-panel-description class="font-sans text-base text-dark-blue-puple">{{ option.moduleName }}</mat-panel-description>
          </mat-expansion-panel-header>

          <ng-container *ngIf="option.hasTopics">
            <mat-accordion *ngFor="let topic of option.topics">
              <mat-expansion-panel
                [id]="topic.id"
                *ngIf="topic.isVisible"
                class="my-0 ml-6 bg-transparent shadow-none"
                [expanded]="textSearch ? topic.isOpen : option.moduleName === selectedModule && topic.moduleName === selectedSubModule"
              >
                <mat-expansion-panel-header id="topic.id" (click)="handleClickSubModule(topic.moduleName)">
                  <mat-panel-description class="font-sans font-normal text-dark-blue-puple">{{ topic.moduleName }}</mat-panel-description>
                </mat-expansion-panel-header>

                <mat-accordion *ngFor="let topicInside of topic.topics">
                  <mat-expansion-panel
                    [id]="topicInside.id"
                    *ngIf="topicInside.isVisible"
                    class="my-0 bg-transparent shadow-none"
                    [expanded]="
                      textSearch
                        ? topicInside.isOpen
                        : option.moduleName === selectedModule &&
                          topic.moduleName === selectedSubModule &&
                          topicInside.moduleName === selectedSubModuleInside
                    "
                  >
                    <mat-expansion-panel-header id="topicInside.id" (click)="handleClickSubModuleInside(topicInside.moduleName)">
                      <mat-panel-description class="w-3/5 font-sans font-normal text-dark-blue-puple">
                        {{ topicInside.moduleName }}</mat-panel-description
                      >
                    </mat-expansion-panel-header>

                    <mat-panel-description class="m-0 flex w-full p-0 font-sans" *ngFor="let subtopic of topicInside.subtopics">
                      <a
                        [id]="subtopic.id"
                        *ngIf="subtopic.isVisible && subtopic.routeName; else subTopicEmBreve"
                        [routerLink]="subtopic.routeName"
                        (click)="handleCleanFilter(subtopic.routeName)"
                        class="w-full cursor-pointer rounded px-4 py-3 text-dark-blue-puple hover:bg-gray-200"
                        >{{ subtopic.name }}
                      </a>

                      <ng-template #subTopicEmBreve>
                        <a
                          [id]="subtopic.id"
                          *ngIf="subtopic.isVisible"
                          class="flex w-full cursor-default flex-row items-center justify-between rounded px-4 py-3 text-dark-blue-puple hover:bg-gray-200"
                        >
                          <span>{{ subtopic.name }}</span>
                          <span class="text-xs font-light italic text-gray-400">em breve</span>
                        </a>
                      </ng-template>
                    </mat-panel-description>
                  </mat-expansion-panel>
                </mat-accordion>

                <mat-panel-description class="m-0 flex w-full p-0 font-sans" *ngFor="let subtopic of topic.subtopics">
                  <a
                    [id]="subtopic.id"
                    *ngIf="subtopic.isVisible && subtopic.routeName; else subTopicEmBreve"
                    [routerLink]="subtopic.routeName"
                    (click)="handleCleanFilter(subtopic.routeName)"
                    class="w-full cursor-pointer rounded px-4 py-3 pl-6 text-dark-blue-puple hover:bg-gray-200"
                    >{{ subtopic.name }}
                  </a>

                  <ng-template #subTopicEmBreve>
                    <a
                      [id]="subtopic.id"
                      *ngIf="subtopic.isVisible"
                      class="flex w-full cursor-default flex-row items-center justify-between rounded px-4 py-3 text-dark-blue-puple hover:bg-gray-200"
                    >
                      <span>
                        {{ subtopic.name }}
                      </span>
                      <span class="text-xs font-light italic text-gray-400">em breve</span>
                    </a>
                  </ng-template>
                </mat-panel-description>
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>

          <mat-panel-description class="m-0 font-sans" *ngFor="let subtopic of option.subtopics">
            <a
              [id]="subtopic.id"
              *ngIf="subtopic.isVisible && subtopic.routeName; else subTopicEmBreve"
              [routerLink]="subtopic.routeName"
              (click)="handleCleanFilter(subtopic.routeName)"
              class="ml-6 w-full cursor-pointer rounded px-6 py-3 text-dark-blue-puple hover:bg-gray-200"
              >{{ subtopic.name }}
            </a>

            <ng-template #subTopicEmBreve>
              <a
                [id]="subtopic.id"
                *ngIf="subtopic.isVisible"
                class="ml-6 flex w-full cursor-default items-center justify-between rounded px-6 py-3 text-dark-blue-puple hover:bg-gray-200"
              >
                {{ subtopic.name }} <span class="text-xs font-light italic text-gray-400">em breve</span>
              </a>
            </ng-template>
          </mat-panel-description>
        </mat-expansion-panel>

       </mat-accordion>
    </div>

    <div class="h-[1%] mt-8 w-full overflow-y-auto flex items-center scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300" *ngIf="isSidebarOpen"
      [ngClass]="isUsuarioOptionsOpen ? 'mb-52' : ''">
      <app-navbar (openOptions)="handleSideBarUsuario($event)"></app-navbar>
    </div>

    <ul [ngClass]="isSidebarOpen ? ' hidden' : 'block'" class="h-full">
      <div class="h-[80%]">
        <li
          class="flex cursor-pointer items-center rounded p-3 text-dark-blue-puple transition-all duration-150 hover:bg-gray-200"
          *ngFor="let option of routes"
          (click)="handleIconSidebar(option)"
          [title]="option.moduleName"
        >
          <span class="material-icons">{{ option.iconName }}</span>
        </li>
    </div>

    <div class="h-[20%] mb-4 pb-2 overflow-y-auto flex items-end justify-end">
      <li
        class="flex  rounded-full cursor-pointer items-center p-3 text-dark-blue-puple transition-all duration-150 bg-gray-200 hover:bg-gray-300"
        (click)="handleIconSideBarUsuario()"
        [title]="'Usuário'"
      >
        <span class="material-icons">person</span>
      </li>
    </div>
    </ul>

  </div>
</aside>

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'app/app.settings';
import { map } from 'rxjs';
import { LocalStorageService } from './localstorage.service';

export type ResponsePaginate<T> = {
  count: number;
  next?: string;
  previous?: string;
  results: T[];
};

export type ResponseOnePaginate<T> = {
  count: number;
  next?: string;
  previous?: string;
  results: T;
};

/**
 * Service genérico responsável por realizar os CRUDs
 * de um determinado recurso, recurso este fornecido pelo
 * generics T.
 */
@Injectable({ providedIn: 'root' })
export class CrudService<T> {
  constructor(private http: HttpClient, private readonly localStorageService: LocalStorageService) {}

  static getFullUrl(path: string): string {
    return `${AppSettings.API_ENDPOINT}/${path}/`;
  }

  static getUrl(path: string): string {
    return `${AppSettings.API_ENDPOINT}/${path}`;
  }

  getFile(path: string, params?: HttpParams) {
    return this.http.get<T>(CrudService.getUrl(path), {
      headers: this.generateHeader(),
      responseType: 'blob' as 'json',
      observe: 'response',
      ...params
    });
  }

  postFile(path: string, params?: HttpParams, bodyParams?: Partial<T>) {
    return this.http.post<T>(CrudService.getUrl(path), bodyParams, {
      headers: this.generateHeader(),
      responseType: 'blob' as 'json',
      observe: 'response',
      ...params
    });
  }

  get(path: string, params?: HttpParams) {
    return this.http.get<T[]>(CrudService.getFullUrl(path), { headers: this.generateHeader(), ...params });
  }

  getWithoutSlash(path: string, params?: HttpParams) {
    return this.http.get<T[]>(CrudService.getUrl(path), { headers: this.generateHeader(), ...params });
  }

  getWithPaginate(path: string, params?: HttpParams) {
    return this.http.get<ResponsePaginate<T>>(CrudService.getUrl(path), { headers: this.generateHeader(), ...params });
  }

  getOne(path: string, params?: HttpParams) {
    return this.http.get<T>(CrudService.getFullUrl(path), { headers: this.generateHeader(), ...params });
  }

  getOneWithoutSlash(path: string, params?: HttpParams) {
    return this.http.get<T>(CrudService.getUrl(path), { headers: this.generateHeader(), ...params });
  }

  getOneWithPaginate(path: string, params?: HttpParams) {
    return this.http.get<ResponseOnePaginate<T> | any>(CrudService.getUrl(path), { headers: this.generateHeader(), ...params });
  }

  post(path: string, valor: Partial<T>, params?: HttpParams) {
    return this.http.post<T>(CrudService.getFullUrl(path), this.formatPayload(valor), { headers: this.generateHeader(), ...params });
  }

  postWithoutSlash(path: string, valor: Partial<T>, params?: HttpParams) {
    return this.http.post<T>(CrudService.getUrl(path), this.formatPayload(valor), { headers: this.generateHeader(), ...params });
  }

  postWithFile(path: string, valor: FormData, params?: HttpParams) {
    let headers = this.generateHeader();
    headers = headers.delete('Content-Type');
    return this.http.post<T>(CrudService.getFullUrl(path), valor, { headers, ...params });
  }

  put(path: string, valor: Partial<T>) {
    return this.http.put<T>(CrudService.getFullUrl(path), this.formatPayload(valor), { headers: this.generateHeader() });
  }

  putWithFile(path: string, valor: FormData) {
    let headers = this.generateHeader();
    headers = headers.delete('Content-Type');
    return this.http.put<T>(CrudService.getFullUrl(path), valor, { headers });
  }

  putWithPipe(path: string, valor: Partial<T>) {
    return this.http.put<T>(CrudService.getFullUrl(path), this.formatPayload(valor), { headers: this.generateHeader() }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  patch(path: string, valor: Partial<T>) {
    return this.http.patch<T>(CrudService.getFullUrl(path), this.formatPayload(valor), { headers: this.generateHeader() });
  }

  delete(path: string) {
    return this.http.delete(CrudService.getFullUrl(path), { headers: this.generateHeader() });
  }

  private formatPayload(payload: any) {
    return JSON.stringify(payload);
  }

  private generateHeader(customHeaders?: HttpHeaders): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...customHeaders
    });

    if (this.localStorageService.getToken()) {
      headers = headers.set('Authorization', `Token ${this.localStorageService.getToken()}`);
    }

    return headers;
  }
}
